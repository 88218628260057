import React from "react";
import { Navigate } from "react-router-dom";
import firebase from 'firebase';

// Layout Types
import { DefaultLayout, LoginLayout, BlankLayout } from "./layouts";

// Route Views
import Dashboard from "./views/Dashboard/index";
import Login from "./views/Login/index";
import Clients from "./views/Clients/index";
import EditClient from "./views/EditClient/index";
import EditClientIntegrations from "./views/EditClientIntegrations/index";
import EditUser from "./views/EditUser/index";
import Users from "./views/Users/index";
import Locations from "./views/Locations/index";
import EditLocation from "./views/EditLocation/index";
import Citations from "./views/Citations/index";
import Violators from "./views/Violators/index";
import Payments from "./views/Payments/index";
import Citation from "./views/Citation/index";
import ForgotPassword from "./views/ForgotPassword/index";
import PermittedVehicles from "./views/PermittedVehicles/index";
import Warnings from "./views/Warnings/index";
import Zones from "./views/Zones/index";
import Tenants from "./views/Tenants/index";
import TenantVehicles from "./views/TenantVehicles/index";
import Activity from "./views/Activity/index";
import CallbackLoading from "./views/CallbackLoading/index";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Navigate to="/dashboard" />
  },
  {
    path: "/login",
    exact: true,
    layout: LoginLayout,
    component: Login
  },
  {
    path: "/forgot-password",
    exact: true,
    layout: LoginLayout,
    component: ForgotPassword
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/clients",
    layout: DefaultLayout,
    component: Clients
  },
  {
    path: "/add-client",
    layout: DefaultLayout,
    component: EditClient
  },
  {
    path: "/edit-client/:clientId",
    layout: DefaultLayout,
    component: EditClient
  },
  {
    path: "/edit-client-integrations/:clientId",
    layout: DefaultLayout,
    component: EditClientIntegrations
  },
  {
    path: "/users",
    layout: DefaultLayout,
    component: Users
  },
  {
    path: "/add-user",
    layout: DefaultLayout,
    component: EditUser
  },
  {
    path: "/edit-user/:userId",
    layout: DefaultLayout,
    component: EditUser
  },
  {
    path: "/locations",
    layout: DefaultLayout,
    component: Locations
  },
  {
    path: "/add-location",
    layout: DefaultLayout,
    component: EditLocation
  },
  {
    path: "/edit-location/:locationId",
    layout: DefaultLayout,
    component: EditLocation
  },
  {
    path: "/location/:locationId/zones",
    layout: DefaultLayout,
    component: Zones
  },
  {
    path: "/permitted-vehicles/:locationId",
    layout: DefaultLayout,
    component: PermittedVehicles
  },
  {
    path: "/citations",
    layout: DefaultLayout,
    component: Citations
  },
  {
    path: "/violators",
    layout: DefaultLayout,
    component: Violators
  },
  {
    path: "/citation/:violationNumber",
    layout: DefaultLayout,
    component: Citation
  },
  {
    path: "/payments",
    layout: DefaultLayout,
    component: Payments
  },
  {
    path: "/warnings",
    layout: DefaultLayout,
    component: Warnings
  },
  {
    path: "/location/:locationId/tenants",
    layout: DefaultLayout,
    component: Tenants
  },
  {
    path: "/tenant-vehicles",
    layout: DefaultLayout,
    component: TenantVehicles
  },
  {
    path: "/activities",
    layout: DefaultLayout,
    component: Activity
  },
  {
    path: "/callback",
    layout: DefaultLayout,
    component: CallbackLoading
  }
];
