/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  FormTextarea,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "shards-react";
import {useParams} from "react-router-dom";
import moment, { localeData } from 'moment-timezone'
import { accessCheck } from "../../utils/tools";
import { WEB_PAYMENT_URL } from "../../environmentVariables";

import PageTitle from "../../components/common/PageTitle";
import ActivityIndicator from "../../components/common/ActivityIndicator";
import Back from "../../components/common/Back";
import { acceptDispute } from "../../actions/locationActions";

const Citation = (props) => {

  let {violationNumber} = useParams();
  const [citation, setCitation] = useState(null)
  const [location, setLocation] = useState(null);
  const [vehicleActivities, setVehicleActivities] = useState([])
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showAdjustModal, setShowAdjustModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showVoidModal, setShowVoidModal] = useState(false)
  const [selectedPercentage, setSelectedPercentage] = useState(10)
  const [newAmount, setNewAmount] = useState(0)
  const [adjustmentNote, setAdjustmentNote] = useState(null)
  const [acceptNote, setAcceptNote] = useState(null)

  useEffect(() => {
    !accessCheck(props.user, 'citations') && (window.location.href = '/')
    props.getSingleCitation(violationNumber)
    return () => props.clearCitation()
  }, [])

  useEffect(() => {
    if (props.citation?.id) {
      setCitation(props.citation)
    }
  }, [props.citation])

  useEffect(() => {
    if (citation) {
      document.title = 'Citation: ' + citation.violation_number + ' - EnforcePlus admin';
      setLocation(props.locations.find(loc => loc.id === citation.location))
      props.getAllVehicleActivities(citation.location, citation.vehicle)
      //props.getAllVehicleActivities(citation.location, citation.vehicle)
    }
  }, [citation])

  useEffect(() => {
    setVehicleActivities(props.vehicleActivities?.length > 0 ? props.vehicleActivities.sort((a, b) => moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1) : [])
  }, [props.vehicleActivities])

  useEffect(() => {
    if (citation) {
      let currentTotal = getCurrentTotal()
      setNewAmount(parseFloat(currentTotal / 100 * (100 - selectedPercentage)).toFixed(2))
    }
  }, [selectedPercentage, citation])

  

  const getCurrentTotal = () => {
    let applyEarlyBird = citation.early_bird_expiry && moment(citation.early_bird_expiry).isAfter(moment()) && citation.dispute?.status !== 'adjusted'
    let applyPenalty = citation.penalty_start_at && moment(citation.penalty_start_at).isBefore(moment())
    let totalToPay = citation.total
    if (applyEarlyBird) {
      totalToPay = citation.early_bird_total
    }
    if (applyPenalty) {
      totalToPay = citation.penalty_total
    }
    if (citation.payment?.amount) {
      totalToPay = citation.payment?.amount - citation.service_fee
    }
    if (citation.status === 'closed' || citation.status === 'void') {
      totalToPay = 0
    }
    return parseFloat(totalToPay).toFixed(2)
  }

  const onDisputeAccept = () => {
    let confirm = window.confirm('Are you sure you want to accept this dispute request?')
    if (confirm) {
      props.acceptDispute(citation, acceptNote)
      setAcceptNote(null)
      setCitation({...citation, dispute: {...citation.dispute, status: 'accepted'}, status: 'closed', total: parseFloat(0).toFixed(2)})      
      setShowAcceptModal(false)
    }
  }

  const onDisputeReject = () => {
    let confirm = window.confirm('Are you sure you want to reject this dispute request?')
    if (confirm) {
      props.rejectDispute(citation, adjustmentNote)
      setAdjustmentNote(null)
      setCitation({...citation, dispute: {...citation.dispute, status: 'rejected'}})
      setShowRejectModal(false)
    }
  }

  const onDisputeAdjust = () => {
    let confirm = window.confirm('Are you sure you want to adjust the balance?')
    if (confirm) {
      props.adjustDispute(citation, newAmount, adjustmentNote)
      setAdjustmentNote(null)
      setCitation({...citation, dispute: {...citation.dispute, status: 'adjusted'}, total: parseFloat(newAmount).toFixed(2)})
      setNewAmount(0)
      setShowAdjustModal(false)
    }
  }

  const onVoidCitation = () => {
    props.voidCitation(citation, adjustmentNote)
    if (citation.dispute) {
      setCitation({...citation, dispute: {...citation.dispute, status: 'accepted'}, status: 'void', total: parseFloat(0).toFixed(2), void_note: adjustmentNote})
    } else {
      setCitation({...citation, status: 'void', total: parseFloat(0).toFixed(2), void_note: adjustmentNote})
    }
    setAdjustmentNote(null)
    setShowVoidModal(false)
  }

  const renderRelatedActivity = () => {
    if (props.loadingVehicleActivities) {
      return (
        <Row>
          <Col className={'text-center'}>
            <ActivityIndicator />
          </Col>
        </Row>
      )
    }
    if (props.loadedVehicleActivities) {
      return (
        <Row>
          <table className="table mb-0">
            <thead className="bg-none">
              <tr>
                <th scope="col" className="border-0">
                  <div onClick={() => {}} style={{cursor: 'pointer'}}>Activity</div>
                </th>
                <th scope="col" className="border-0">
                  <div onClick={() => {}} style={{cursor: 'pointer'}}>Date/time</div>
                </th>
                <th scope="col" className="border-0">
                  <div onClick={() => {}} style={{cursor: 'pointer'}}>User</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {citation.payment_on && (
                <tr key={'paid-0'}>
                  <td>
                    PAID
                  </td>
                  <td>
                    {moment(citation.payment_on).format('M/D/Y h:mm a')}
                  </td>
                  <td>
                    ${(parseFloat(citation.payment.amount) - parseFloat(citation.service_fee)).toFixed(2)}
                  </td>
                </tr>
              )}
              {citation.voided_on && (
                <>
                  <tr key={'void-0'}>
                    <td>
                      VOID
                    </td>
                    <td>
                      {moment(citation.voided_on).format('M/D/Y h:mm a')}
                    </td>
                    <td>
                      {props.users.find(user => user.id === citation.voided_by)?.name}
                    </td>
                  </tr>
                  {!!citation.voided_note && (
                    <tr>
                      <td style={{border: '0px solid white', paddingTop: 0}}></td>
                      <td colSpan={2} style={{border: '0px solid white', paddingTop: 0}}>{citation.voided_note}</td>
                    </tr>
                  )}
                </>
              )}
              {citation.dispute?.adjusted_on && (
                <tr key={'adjusted-0'}>
                  <td>
                    ADJUSTED
                  </td>
                  <td>
                    {moment(citation.dispute?.adjusted_on).format('M/D/Y h:mm a')}
                  </td>
                  <td>
                    {props.users.find(user => user.id === citation.dispute?.adjusted_by)?.name}
                  </td>
                </tr>
              )}
              {citation.dispute?.rejected_on && (
                <tr key={'rejected-0'}>
                  <td>
                    REJECTED
                  </td>
                  <td>
                    {moment(citation.dispute?.rejected_on).format('M/D/Y h:mm a')}
                  </td>
                  <td>
                    {props.users.find(user => user.id === citation.dispute?.rejected_by)?.name}
                  </td>
                </tr>
              )}
              {citation.dispute?.accepted_on && (
                <tr key={'accepted-0'}>
                  <td>
                    ACCEPTED
                  </td>
                  <td>
                    {moment(citation.dispute?.accepted_on).format('M/D/Y h:mm a')}
                  </td>
                  <td>
                    {props.users.find(user => user.id === citation.dispute?.accepted_by)?.name}
                  </td>
                </tr>
              )}
              {citation.disputed_on && (
                <tr key={'disputed-0'}>
                  <td>
                    DISPUTED
                  </td>
                  <td>
                    {moment(citation.disputed_on).format('M/D/Y h:mm a')}
                  </td>
                  <td>
                    {citation.dispute?.name}
                  </td>
                </tr>
              )}
              {citation.created_at && (
                <tr key={'cited-0'}>
                  <td>
                    CITED
                  </td>
                  <td>
                    {moment(citation.created_at).format('M/D/Y h:mm a')}
                  </td>
                  <td>
                    {props.users.find(user => user.id === citation.user)?.name}
                  </td>
                </tr>
              )}
              {vehicleActivities?.map(activity => (
                <tr key={activity.id}>
                  <td>
                    {activity.activity_name.toUpperCase()}
                  </td>
                  <td>
                    {moment(activity.created_at).format('M/D/Y h:mm a')}
                  </td>
                  <td>
                    {props.users.find(user => user.id === activity.user)?.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>    
        </Row>
      )
    }
  }

  if (citation) {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
  
        {/* First Row of Posts */}
        <Row>
          <Col>
            <Row noGutters className="page-header pb-2 pt-4">
              <PageTitle xs="12" sm="8" title={"Citation: " + citation.violation_number} className="text-sm-left mb-3" />
              <Col xs="12" sm="4" className='text-right'>
                <Row>
                  <Col className='text-right pl-0 pr-1'>
                    
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Back />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col xs={12}>
                    <Card small className="mb-4 mt-4">
                      <CardBody>
                        <Row>
                          <Col xs={7}>
                            <span style={{fontSize: 11, textTransform: 'uppercase'}}>original balance</span><br />
                            <span style={{fontSize: 30}}>${parseFloat(citation.violations_total).toFixed(2)}</span>
                          </Col>
                          <Col xs={5}>
                            <span style={{fontSize: 11, textTransform: 'uppercase'}}>{citation.payment_on ? 'paid' : 'current'} balance {!!citation.payment_on && '(ex. fees)'}</span><br />
                            <span style={{fontSize: 30}}>${getCurrentTotal()}</span>
                          </Col>
                        </Row>
                        <Row className={'pt-2'}>
                          <Col><span style={{fontSize: 11, textTransform: 'uppercase'}}>Client:</span>&nbsp;&nbsp;&nbsp;<span style={{fontSize: 12}}>{citation.client_name}</span></Col>
                        </Row>
                        <Row className={'pt-0'}>
                          <Col><span style={{fontSize: 11, textTransform: 'uppercase'}}>Location:</span>&nbsp;&nbsp;&nbsp;<span style={{fontSize: 12}}>{citation.location_name}</span></Col>
                        </Row>
                        <Row className={'pt-0 pb-2'}>
                          <Col><span style={{fontSize: 11, textTransform: 'uppercase'}}>Date/Time:</span>&nbsp;&nbsp;&nbsp;<span style={{fontSize: 12}}>{moment(citation.created_at).format('M/D/Y h:mm a')}</span></Col>
                        </Row>
                        {citation.payment_on && (
                          <>
                            <Row className={'pt-0'}>
                              <Col><span style={{fontSize: 11, textTransform: 'uppercase'}}>Payment name:</span>&nbsp;&nbsp;&nbsp;<span style={{fontSize: 12}}>{citation.payment.name}</span></Col>
                            </Row>
                            <Row className={'pt-0 pb-2'}>
                              <Col><span style={{fontSize: 11, textTransform: 'uppercase'}}>Payment email:</span>&nbsp;&nbsp;&nbsp;<span style={{fontSize: 12}}>{citation.payment.email}</span></Col>
                            </Row>
                          </>
                        )}
                      </CardBody>
                      <CardBody style={{borderTop: '1px solid #EEEFF1'}}>
                        <Row className={'mb-2'}>
                          <Col><span style={{fontSize: 11, textTransform: 'uppercase'}}>violations</span></Col>
                        </Row>
                        {citation.violations.map(violation => (
                          <Row>
                            <Col xs={6}><span style={{fontSize: 14}}>{violation.name}</span></Col>
                            <Col xs={6}><span style={{fontSize: 14}}>{violation?.amount > 0 ? '$' + parseFloat(violation.amount).toFixed(2) : 'WARNING'}</span></Col>
                          </Row>
                        ))}
                      </CardBody>
                      {citation.images?.length > 0 && (
                        <CardBody style={{borderTop: '1px solid #EEEFF1'}}>
                          <Row>
                            {citation.images.map(image => (
                              <Col>
                                <div style={{height: 68, width: 100, overflow: 'hidden', marginLeft: 'auto', marginRight: 'auto'}}>
                                  <a href={image.url} target={'_blank'}>
                                    <img src={image.url} style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                  </a>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </CardBody>
                      )}
                    </Card>
                    {(!!citation?.public_notes || !!citation.private_notes) && (
                      <Card small className="mb-4 mt-4">
                        <CardBody>
                          <Row>
                            <Col className={'text-center pt-2 pb-4'}><span style={{fontSize: 16, fontWeight: 'bold'}}>Notes</span></Col>
                          </Row>
                          {!!citation?.public_notes && (
                            <Row style={{marginBottom: 16}}>
                              <Col>
                                <span style={{fontSize: 11, fontWeight: 'normal', textTransform: 'uppercase'}}>Public notes:</span><br />
                                <span style={{fontSize: 12}}>{citation?.public_notes}</span>
                              </Col>
                            </Row>
                          )}
                          {!!citation?.private_notes && (
                            <Row style={{marginBottom: 16}}>
                              <Col>
                                <span style={{fontSize: 11, fontWeight: 'normal', textTransform: 'uppercase'}}>Private notes:</span><br />
                                <span style={{fontSize: 12}}>{citation?.private_notes}</span>
                              </Col>
                            </Row>
                          )}
                        </CardBody>
                      </Card>
                    )}

                    <Card small className="mb-4 mt-4">
                      <CardBody>
                        <Row>
                          <Col className={'text-center pt-2 pb-4'}><span style={{fontSize: 16, fontWeight: 'bold'}}>Printed citation</span></Col>
                        </Row>
                        <Row>
                          <Col>
                            <iframe src={WEB_PAYMENT_URL + '/citation/' + citation.violation_number} style={{border: 'none', width: '100%', scrollbars: 'auto', height: 1500}}/>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col xs={12}>
                    {(!citation.disputed_on || citation.dispute?.rejected_on) && citation?.status !== 'void' && (
                      <Card small className="mb-4 mt-4">
                        <CardBody className={'pt-0 pb-0'}>
                          <Row>
                            <Col className={'text-center pt-4'}><span style={{fontSize: 16, fontWeight: 'bold'}}>Actions</span></Col>
                          </Row>
                          <Row className={'mt-4 mb-4'} >
                            <Col xs={6} className={'text-center'}>
                              <Button
                                theme="secondary"
                                style={{backgroundColor: '#FAD100', width: 120, borderColor: '#FAD100'}}
                                onClick={() => setShowAdjustModal(true)}
                              >Adjust</Button>
                            </Col>
                            <Col xs={6} className={'text-center'}>
                              <Button
                                theme="secondary"
                                style={{backgroundColor: '#000000', width: 120, borderColor: '#000000'}}
                                onClick={() => setShowVoidModal(true)}
                              >Void</Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                    {citation.disputed_on && (
                      <Card small className="mb-4 mt-4">
                        <CardBody className={'pt-0 pb-0'}>
                          <Row style={{height: 70}}>
                            <Col className={'text-center pt-3'} style={{backgroundColor: '#F98A01'}}>
                              <span style={{fontSize: 22, color: '#FFFFFF', lineHeight: 0.5}}>DISPUTED</span><br />
                              <span style={{fontSize: 14, color: '#FFFFFF'}}>{moment(citation.disputed_on).format('M/D/Y h:mm a')}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col className={'p-4'}>
                              <Row className={'mb-3'}>
                                <Col xs={5} style={{fontSize: 14}}><span style={{fontWeight: 'bold'}}>Name:</span><br/>{citation.dispute?.name}</Col>
                                <Col xs={7} style={{fontSize: 14}}><span style={{fontWeight: 'bold'}}>Email:</span><br />{citation.dispute?.email}</Col>
                              </Row>
                              {!!citation?.dispute?.mobile && (
                                <Row className={'mb-3'}>
                                  <Col xs={12} style={{fontSize: 14}}><span style={{fontWeight: 'bold'}}>Mobile:</span><br/>{citation.dispute?.mobile}</Col>
                                </Row>
                              )}
                              <Row className={'mb-3'}>
                                <Col style={{fontSize: 14}}><span style={{fontWeight: 'bold'}}>Reason for dispute:</span><br />{citation.dispute?.reason}</Col>
                              </Row>
                              {citation.dispute?.evidence?.length > 0 && (
                                <Row className={'mb-2'}>
                                  <Col style={{fontSize: 14}}><span style={{fontWeight: 'bold'}}>Evidence attached:</span><br />
                                  {citation.dispute.evidence.map(evidence => (
                                    <><a href={evidence?.url} target={'_blank'}>{evidence?.name}</a><br /></>
                                  ))}</Col>
                                </Row>
                              )}
                              <Row className={'mt-4'} >
                                {citation.dispute.status === 'open' && (
                                  <>
                                    <Col xs={3} className={'text-left'}>
                                      <Button
                                        theme="secondary"
                                        style={{backgroundColor: '#FAD100', width: 90, borderColor: '#FAD100'}}
                                        onClick={() => setShowAdjustModal(true)}
                                      >Adjust</Button>
                                    </Col>
                                    <Col xs={3} className={'text-center'}>
                                      <Button
                                        theme="secondary"
                                        style={{backgroundColor: '#C81712', width: 90, borderColor: '#C81712'}}
                                        onClick={() => setShowRejectModal(true)}
                                      >Reject</Button>
                                    </Col>
                                    <Col xs={3} className={'text-center'}>
                                      <Button
                                        theme="secondary"
                                        style={{backgroundColor: '#21AA73', width: 90, borderColor: '#21AA73'}}
                                        onClick={() => setShowAcceptModal(true)}
                                      >Accept</Button>
                                    </Col>
                                  </>
                                )}
                                {citation.dispute.status !== 'void' && citation.dispute.status !== 'accepted'  && (
                                  <Col xs={3} className={'text-right'}>
                                    <Button
                                      theme="secondary"
                                      style={{backgroundColor: '#000000', width: 90, borderColor: '#000000'}}
                                      onClick={() => setShowVoidModal(true)}
                                    >Void{citation.dispute.status}</Button>
                                  </Col>)
                                }
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                    
                    <Card small className="mb-4 mt-4">
                      <CardBody>
                        <Row>
                          <Col className={'text-center pt-2 pb-4'}><span style={{fontSize: 16, fontWeight: 'bold'}}>Related activity</span></Col>
                        </Row>
                        {renderRelatedActivity()}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal size={'md'} open={showAdjustModal}>
          <ModalHeader toggle={() => setShowAdjustModal(false)}>Adjust balance</ModalHeader>
          <ModalBody className={'pt-4 pb-6'}>
            <Row style={{marginBottom: 24}}>
              <Col xs={7}>
                <span style={{fontSize: 11, textTransform: 'uppercase'}}>current balance</span><br />
                <span style={{fontSize: 30}}>${getCurrentTotal()}</span>
              </Col>
              <Col xs={5}>
                
              </Col>
            </Row>
            <Row style={{marginBottom: 24}}>
              <Col className={'text-center'}>
                <div onClick={() => setSelectedPercentage(10)} style={{cursor: 'pointer', height: 60, width: 90, borderRadius: 5, backgroundColor: selectedPercentage === 10 ? '#F98A01' : '#F8F8F8', border: selectedPercentage === 10 ? '1px solid #F98A01' : '1px solid #CECECE', textAlign: 'center', paddingTop: 14, marginRight: 'auto'}}>
                  <span style={{textAlign: 'center', color: selectedPercentage === 10 ? 'black' : '#CECECE', fontSize: 18}}>10%</span>
                </div>
              </Col>
              <Col>
                <div onClick={() => setSelectedPercentage(25)} style={{cursor: 'pointer', height: 60, width: 90, borderRadius: 5, backgroundColor: selectedPercentage === 25 ? '#F98A01' : '#F8F8F8', border: selectedPercentage === 25 ? '1px solid #F98A01' : '1px solid #CECECE', textAlign: 'center', paddingTop: 14, marginLeft: 'auto', marginRight: 'auto'}}>
                  <span style={{textAlign: 'center', color: selectedPercentage === 25 ? 'black' : '#CECECE', fontSize: 18}}>25%</span>
                </div>
              </Col>
              <Col>
                <div onClick={() => setSelectedPercentage(50)} style={{cursor: 'pointer', height: 60, width: 90, borderRadius: 5, backgroundColor: selectedPercentage === 50 ? '#F98A01' : '#F8F8F8', border: selectedPercentage === 50 ? '1px solid #F98A01' : '1px solid #CECECE', textAlign: 'center', paddingTop: 14, marginLeft: 'auto'}}>
                  <span style={{textAlign: 'center', color: selectedPercentage === 50 ? 'black' : '#CECECE', fontSize: 18}}>50%</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <label htmlFor="feNewAmount" className="form-label-custom">new balance</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    id={'new-balance'}
                    value={newAmount}
                    onChange={(event) => setNewAmount(event.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <label htmlFor="feNewAmount" className="form-label-custom">{'Adjustment note (seen by customer)'}</label>
                <FormTextarea
                  id={'adjust-note'}
                  value={adjustmentNote}
                  onChange={(event) => setAdjustmentNote(event.target.value)}
                  style={{height: 60}}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{display: 'block !important'}}>
            <Row fluid>
              <Col xs={6} className={'text-left'}>
                <Button
                  theme="outline-secondary"
                  onClick={() => setShowAdjustModal(false)}
                  className={'mr-2'}
                >Cancel</Button>
              </Col>
              <Col xs={6} className={'text-right'}>
                <Button
                  theme="accent"
                  onClick={() => onDisputeAdjust()}
                >Save</Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal size={'md'} open={showRejectModal}>
          <ModalHeader toggle={() => setShowRejectModal(false)}>Reject dispute</ModalHeader>
          <ModalBody className={'pt-4 pb-6'}>
            <Row>
              <Col xs={12}>
                <label htmlFor="feNewAmount" className="form-label-custom">{'Rejection note (seen by customer)'}</label>
                <FormTextarea
                  id={'adjust-note'}
                  value={adjustmentNote}
                  onChange={(event) => setAdjustmentNote(event.target.value)}
                  style={{height: 60}}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{display: 'block !important'}}>
            <Row fluid>
              <Col xs={6} className={'text-left'}>
                <Button
                  theme="outline-secondary"
                  onClick={() => setShowRejectModal(false)}
                  className={'mr-2'}
                >Cancel</Button>
              </Col>
              <Col xs={6} className={'text-right'}>
                <Button
                  theme="accent"
                  onClick={() => onDisputeReject()}
                >Save</Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>  
        <Modal size={'md'} open={showAcceptModal}>
          <ModalHeader toggle={() => setShowAcceptModal(false)}>Accept dispute</ModalHeader>
          <ModalBody className={'pt-4 pb-6'}>
            <Row>
              <Col xs={12}>
                <label htmlFor="feNewAmount" className="form-label-custom">{'Accept note (seen by customer)'}</label>
                <FormTextarea
                  id={'accept-note'}
                  value={acceptNote}
                  onChange={(event) => setAcceptNote(event.target.value)}
                  style={{height: 60}}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{display: 'block !important'}}>
            <Row fluid>
              <Col xs={6} className={'text-left'}>
                <Button
                  theme="outline-secondary"
                  onClick={() => setShowAcceptModal(false)}
                  className={'mr-2'}
                >Cancel</Button>
              </Col>
              <Col xs={6} className={'text-right'}>
                <Button
                  theme="accent"
                  onClick={() => onDisputeAccept()}
                >Accept</Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>  
        <Modal size={'md'} open={showVoidModal}>
          <ModalHeader toggle={() => setShowVoidModal(false)}>Void citation</ModalHeader>
          <ModalBody className={'pt-4 pb-6'}>
            <Row>
              <Col xs={12}>
                <label htmlFor="feNewAmount" className="form-label-custom">{'Void note'}</label>
                <FormTextarea
                  id={'adjust-note'}
                  value={adjustmentNote}
                  onChange={(event) => setAdjustmentNote(event.target.value)}
                  style={{height: 60}}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{display: 'block !important'}}>
            <Row fluid>
              <Col xs={6} className={'text-left'}>
                <Button
                  theme="outline-secondary"
                  onClick={() => setShowVoidModal(false)}
                  className={'mr-2'}
                >Cancel</Button>
              </Col>
              <Col xs={6} className={'text-right'}>
                <Button
                  theme="accent"
                  onClick={() => onVoidCitation()}
                >Save</Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>  
      </Container>
    );
  } else {
    return (
      <Row style={{paddingTop: 200, paddingBottom: 200}}>
        <Col className={'text-center'}>
          <ActivityIndicator />
        </Col>
      </Row>
    )
  }
  
}

export default Citation;
