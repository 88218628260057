/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
  FormInput,
} from "shards-react";
import { accessCheck } from "../../utils/tools";
import moment from 'moment-timezone'
import { WEB_PAYMENT_URL } from "../../environmentVariables";

import DownloadButton from "../../components/common/DownloadButton";
import PageTitle from "../../components/common/PageTitle";
import Pagination from "../../components/common/Pagination";
import ActivityIndicator from "../../components/common/ActivityIndicator";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import DatePicker from "react-datepicker";
import { startOfWeek, endOfWeek } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

const Activity = (props) => {

  const [citations, setCitations] = useState([]);
  const [startDate, setStartDate] = useState(startOfWeek(new Date()))
  const [endDate, setEndDate] = useState(endOfWeek(new Date()))
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState(!searchParams.get("view") ? props.statusFilter : searchParams.get("view"));
  const [client, setClient] = useState('all');
  const [location, setLocation] = useState('all');
  const [sortBy, setSortBy] = useState('dateDesc')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize] = useState(100)
  const [endablePdfMode, setEndablePdfMode] = useState(false);

  const csvHeader = [
    'Name',
    'Activity',
    'Date/Time',
    'Location',        
    'Violation'
  ]

  const generateFileData = () => {
    let citationsTemp = [...filterCitations()]
    let csvDataArray = citationsTemp.map(citation => ([
      props.users.find(user => user.id === citation.user)?.name,
      citation.activity,
      moment(citation.created_at).format('MM/DD/Y h:mm a'),
      citation.location_name,
      citation.violation_number
    ]))
    return [csvHeader, ...csvDataArray]
  }

  const generateList = () => {
    if (endablePdfMode) {
      return filterCitations()
    } else {
      return filterCitations().slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize);
    }
  }

  const getTargetElement = () => document.getElementById('content-id');

  const generateFilePdf = () => {
    const options = {
      method: 'save',
      resolution: Resolution.NORMAL,
      page: {
        margin: Margin.SMALL,
        format: 'A4',
        orientation: 'portrait',
      },
      canvas: {
        mimeType: 'image/jpeg',
        qualityRatio: 1
      },
      overrides: {
        pdf: {
          compress: true
        },
        canvas: {
          useCORS: true
        }
      },
      filename: 'enforceplus_activities.pdf'
    };

    setEndablePdfMode(true);
    setTimeout(() => {
      generatePDF(getTargetElement, options);
      setTimeout(() => {
        setEndablePdfMode(false);
      }, 1000);
    }, 1000);
  }

  useEffect(() => {
    props.getAllCitations(startDate, endDate)
    document.title = 'Activity: EnforcePlus admin';
    !accessCheck(props.user, 'citations') && (window.location.href = '/')
    props.clearCitation()
  }, [])

  useEffect(() => {
    props.getAllCitations(startDate, endDate)
  }, [startDate, endDate])

  useEffect(() => {
    const combinedCitations = props.citations.flatMap((citation) => {
      const activities = [];
  
      // Always add the base "Citation" activity
      activities.push({
        ...citation,
        activity: "Citation",
      });
  
      // Add "Accepted" activity if condition is met
      if (citation?.dispute?.accepted_by && citation.dispute.accepted_on) {
        activities.push({
          ...citation,
          activity: "Accepted",
          created_at: citation.dispute.accepted_on,
          user: citation.dispute.accepted_by,
          note: citation?.dispute?.accepted_note
        });
      }
  
      // Add "Adjusted" activity if condition is met
      if (citation?.dispute?.adjusted_by && citation.dispute.adjusted_on) {
        activities.push({
          ...citation,
          activity: "Adjusted",
          created_at: citation.dispute.adjusted_on,
          user: citation.dispute.adjusted_by,
          reason: citation?.dispute?.reason
        });
      }
  
      // Add "Voided" activity if condition is met
      if (citation.voided_by && citation.voided_on) {
        activities.push({
          ...citation,
          activity: "Voided",
          created_at: citation.voided_on,
          user: citation.voided_by
        });
      }
  
      return activities; // Return all matching activities for this citation
    });
  
    setCitations(combinedCitations);
  }, [props.citations]);

  // useEffect(() => {
  //   if (props.addedLocation) {
  //     setAddedLocation(true);
  //     setTimeout(() => {
  //       setAddedLocation(false)
  //       props.clearAddedLocation()
  //     }, 10000);
  //   }
  //   if (props.updatedLocation) {
  //     setUpdatedLocation(true);
  //     setTimeout(() => {
  //       setUpdatedLocation(false)
  //       props.clearAddedLocation()
  //     }, 10000);
  //   }
  // }, [props.addedLocation, props.updatedLocation])

  
  
  const filterCitations = () => {
    let sortedCitations = [...citations];
    if (search !== '') {
      sortedCitations = sortedCitations.filter(citation => (
        citation.violation_number.toLowerCase().indexOf(search.toLowerCase()) !== -1
        || citation.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1
        || citation.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1
        || props.users?.find(user => user.id === citation.user)?.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ))
    }
    if (status !== 'all') {
      props.setStatusFilter(status)
      if (status === 'paid') {
        sortedCitations = sortedCitations.filter(citation => citation.payment_on)
      } else if (status === 'closed') {
        sortedCitations = sortedCitations.filter(citation => citation.status === 'closed')
      } else if (status === 'void') {
        sortedCitations = sortedCitations.filter(citation => citation.status === 'void')
      } else if (status === 'unpaid') {
        sortedCitations = sortedCitations.filter(citation => !citation.payment_on && citation.status !== 'closed' && citation.status !== 'void')
      } else {
        sortedCitations = sortedCitations.filter(citation => citation.dispute?.status === 'open' && !citation.payment_on)
      }
    }
    if (status === 'all') {
      props.setStatusFilter(status)
    }
    if (client !== 'all') {
      sortedCitations = sortedCitations.filter(citation => citation.client === client)
    }
    if (location !== 'all') {
      sortedCitations = sortedCitations.filter(citation => citation.location === location)
    }
    if (sortBy === 'dateDesc') {
      sortedCitations = sortedCitations.sort((a, b) => a.created_at && b.created_at && moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1);
    }
    if (sortBy === 'dateAsc') {
      sortedCitations = sortedCitations.sort((a, b) => a.created_at && b.created_at && moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1);
    }
    if (sortBy === 'userAsc') {
      sortedCitations = sortedCitations.sort((a, b) => props.users.find(user => user.id === a.user)?.name?.localeCompare(props.users.find(user => user.id === b.user)?.name));
    }
    if (sortBy === 'userDesc') {
      sortedCitations = sortedCitations.sort((a, b) => props.users.find(user => user.id === b.user)?.name?.localeCompare(props.users.find(user => user.id === a.user)?.name));
    }
    if (sortBy === 'activityAsc') {
      sortedCitations = sortedCitations.sort((a, b) => (a.activity).localeCompare((b.activity)));    
    }
    if (sortBy === 'activityDesc') {
      sortedCitations = sortedCitations.sort((a, b) => (b.activity).localeCompare((a.activity)));    
    }

    return sortedCitations.slice()
  }

  const citationAction = (citation, action) => {
    if (action === 'view_receipt') {
      window.open(citation.payment.receipt_url)
    }
    if (action === 'view_citation') {
      window.location.href = './citation/' + citation.violation_number
    }
    if (action === 'mark_paid') {
      let tempCitations = [...citations]
      let index = tempCitations.findIndex(cit => cit.id === citation.id)
      tempCitations[index].status = "paid"
      tempCitations[index].payment_on = moment.utc().toISOString()
      tempCitations[index].payment = {
        type: 'other',
        amount: parseFloat(citation.violations_total).toFixed(2)
      }
      props.markAsPaid(citation)
      setCitations(tempCitations)
    }
    if (action === 'view_dispute') {
      //window.open(WEB_PAYMENT_URL + '/citation/' + citation.violation_number)
    }
    if (action === 'take_payment') {
      window.open(WEB_PAYMENT_URL + '/violations/' + citation.vehicle)
    }
  }

  const getCsvStatus = (citation) => {
    if (citation?.status === 'void') {
      return ('Void')
    } else if (citation?.payment_on) {
      return ('Paid')
    } else if (citation?.dispute?.status === 'open') {
      return ('Disputed')
    } else if (citation?.status === 'closed') {
      return ('Closed')
    } else {
      return ('Unpaid')
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      {/* First Row of Posts */}
      <Row>
        <Col>
          <Row noGutters className="page-header py-4">
            <PageTitle xs="12" sm="4" title="Activities" className="text-sm-left mb-3" />
            <Col xs="12" sm="8" className='text-right'>
              <Row>
                <Col xs="1"></Col>
                <Col className='text-right pl-0 pr-1'>
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} customInput={<FormInput style={{textAlign: 'right'}} />} maxDate={new Date()} />
                  <div className={'d-none d-xl-block'} style={{position: 'absolute', top: 4, left: 8}}><span style={{fontSize: 11, fontWeight: 'normal'}}>From</span></div>
                </Col>
                <Col className='text-right pl-0 pr-1'>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} customInput={<FormInput style={{textAlign: 'right'}} />} minDate={startDate} />
                  <div className={'d-none d-xl-block'} style={{position: 'absolute', top: 4, left: 8}}><span style={{fontSize: 11, fontWeight: 'normal'}}>To</span></div>
                </Col>
                {props.user.type === 'super' && (
                  <Col className='text-right pl-0 pr-1'>
                    <FormSelect id="feClients" onChange={event => setClient(event.target.value)}>
                      <option value={'all'}>All clients</option>
                      {props.clients?.sort((a, b) => a.name.localeCompare(b.name)).map(client => (
                        <option value={client.id}>{client.name}</option>
                      ))}
                    </FormSelect>
                  </Col>
                )}
                <Col className='text-right pl-0 pr-1'>
                  <FormSelect value={location} id="feLocations" onChange={event => setLocation(event.target.value)}>
                    <option value={'all'}>All locations</option>
                    {props.locations?.sort((a, b) => a.name.localeCompare(b.name)).map(location => (
                      <option value={location.id}>{location.name}</option>
                    ))}
                  </FormSelect>
                </Col>
                <Col className='text-right pl-0 pr-1'>
                  <FormSelect id="feInputState" value={status} onChange={event => setStatus(event.target.value)}>
                    <option value={'all'}>All statuses</option>
                    <option value={'paid'}>Paid</option>
                    <option value={'unpaid'}>Unpaid</option>
                    <option value={'disputed'}>Disputed</option>
                    <option value={'closed'}>Closed</option>
                    <option value={'void'}>Void</option>
                  </FormSelect>
                </Col>
                <DownloadButton
                  filename={"enforceplus_activities"}
                  fileData={generateFileData()}
                  showPdfLoading={endablePdfMode}
                  createPdf={() => generateFilePdf()}
                />
              </Row>
            </Col>
          </Row>
          {props.gettingAllCitations ? (
            <Row>
              <Col className={'text-center'}>
                <ActivityIndicator />
              </Col>
            </Row>
          ) : (
            <>  
              <Card small className="mb-4 mt-4">
                <CardHeader>
                  <Row>
                    <Col xs={8}>&nbsp;</Col>
                    <Col xs={4} className='text-right pl-0'>
                      <FormInput type={'text'} className={'form-control'} placeholder={'Search'} value={search} onChange={event => setSearch(event.target.value)} />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0" id="content-id">
                  <table className="table mb-0">
                    <thead className="bg-none">
                      <tr>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'userAsc' ? 'userAsc' : 'userDesc')} style={{cursor: 'pointer'}}>Name</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'activityAsc' ? 'activityAsc' : 'activityDesc')} style={{cursor: 'pointer'}}>Activity</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'dateDesc' ? 'dateDesc' : 'dateAsc')} style={{cursor: 'pointer'}}>Date/Time</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'dateDesc' ? 'dateDesc' : 'dateAsc')} style={{cursor: 'pointer'}}>Location</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'dateDesc' ? 'dateDesc' : 'dateAsc')} style={{cursor: 'pointer'}}>Violation</div>
                        </th>                                                
                      </tr>
                    </thead>
                    <tbody>
                      {props.citations && filterCitations().length > 0 && generateList().map(citation => {
                        return (
                          <>
                            <tr key={citation.id}>
                              <td style={{paddingTop: 24}} className={"d-none d-md-table-cell"}>
                                {props.users.find(user => user.id === citation.user)?.name}
                              </td>
                              <td style={{paddingTop: 24}} className={"d-none d-md-table-cell"}>
                                {citation.activity}
                              </td>
                              <td style={{paddingTop: 24}}>
                                {moment(citation.created_at).format('MM/DD/Y h:mm a')}
                              </td>
                              <td style={{paddingTop: 24}}>
                                {citation.location_name}
                              </td>
                              <td style={{paddingTop: 24}}>
                                {citation.violation_number}
                              </td>
                            </tr>
                            {citation.reason && (
                              <tr>
                                <td colSpan="5" className="border-top-0 text-muted" style={{paddingTop: 0}}>
                                  <small>Reason: {citation.reason}</small>
                                </td>
                              </tr>
                            )}
                            {citation.note && (
                              <tr>
                                <td colSpan="5" className="border-top-0 text-muted" style={{paddingTop: 0}}>
                                  <small>Note: {citation.note}</small>
                                </td>
                              </tr>
                            )}
                            <tr className="d-md-none d-xs-table-row">
                              <td className="border-top-0"></td>
                              <td colSpan={3} className={"text-right border-top-0"}>
                                <FormSelect id="feInputState" onChange={event => {citationAction(citation, event.target.value); event.target.value = ''}}>
                                  <option value={''}>Actions</option>
                                  {citation.payment_on && citation.payment?.receipt_url && (
                                    <option value={'view_receipt'}>View receipt</option>
                                  )}
                                  <option value={'view_citation'}>View citation</option>
                                  {citation.status === 'unpaid' && (
                                    <>
                                      <option value={'mark_paid'}>Mark as paid</option>
                                      <option value={'take_payment'}>Take payment</option>
                                    </>
                                  )}
                                </FormSelect>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Pagination
                data={filterCitations()}
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}      
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Activity;
