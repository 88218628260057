import Warnings from './Warnings';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient, getLocation, getCitation} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, users} = getUser(state);
  const {clients, client} = getClient(state);
  const {locations, location} = getLocation(state);
  const {getAllCitationsByViolator, gotAllCitations, warnings} = getCitation(state);

  return {
    user,
    users,
    clients,
    client,
    locations,
    location,
    getAllCitationsByViolator,
    gotAllCitations,
    warnings
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Warnings);
