import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import {
  Col,
  FormSelect,
} from "shards-react";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import XLSX from 'xlsx';
import { Modal, ModalBody, ModalHeader } from "shards-react";


const DownloadButton = ({
  filename = "",
  fileData = [],
  showPdfLoading = false,
  createPdf
}) => {
  const [typeFile, setTypeFile] = useState('csv');

  const generateExcel = () => {
    if (fileData.length > 0) {
      let tempList = [];
      let headers = fileData[0];

      for (let x = 0; x < fileData.length; x++) {
        if (x > 0) {
          let tempData = {};
          for (let y = 0; y < fileData[x].length; y++) {
            const element = fileData[x][y];
            tempData[headers[y]] = element;
          }
          tempList.push(tempData)
        }
      }
  
      const ws = XLSX.utils.json_to_sheet(tempList);
      /* add to workbook */
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Logs");
      /* generate an XLSX file */
      XLSX.writeFile(wb, filename+".xlsx");
    }
  }

  return (
    <Col className='text-right pl-0 pr-1 btn-download-container'>
      <FormSelect className="" value={typeFile} id="typefile" onChange={event => setTypeFile(event.target.value)}>
        <option value={'csv'}>CSV</option>
        <option value={'xls'}>XLS</option>
        <option value={'pdf'}>PDF</option>
      </FormSelect>
      {typeFile === "csv" &&
        <CSVLink data={fileData} filename={filename+".csv"}>
          <span style={{fontSize: 20}}>
            <FontAwesomeIcon icon={faDownload} color={'#3D3C3E'} />
          </span>
        </CSVLink>
      }
      {typeFile === "xls" &&
        <div onClick={() => generateExcel()}>
          <span style={{fontSize: 20, cursor: "pointer"}}>
            <FontAwesomeIcon icon={faDownload} color={'#3D3C3E'} />
          </span>
        </div>
      }
      {typeFile === "pdf" &&
        <div onClick={() => createPdf()}>
          <span style={{fontSize: 20, cursor: "pointer"}}>
            <FontAwesomeIcon icon={faDownload} color={'#3D3C3E'} />
          </span>
        </div>
      }
      <Modal open={showPdfLoading}>
        <ModalHeader>Creating pdf</ModalHeader>
        <ModalBody>Please wait</ModalBody>
      </Modal>
    </Col>
  )
};

DownloadButton.propTypes = {
};

export default DownloadButton;
