export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SENDING_PASSWORD_RESET = 'SENDING_PASSWORD_RESET';
export const SENT_PASSWORD_RESET = 'SENT_PASSWORD_RESET';

export const LOADING_USER = 'LOADING_USER';
export const LOADED_USER = 'LOADED_USER';
export const LOADING_USERS = 'LOADING_USERS';
export const LOADED_USERS = 'LOADED_USERS';
export const UPDATING_USER = 'UPDATING_USER';
export const UPDATED_USER = 'UPDATED_USER';
export const ADDING_USER = 'ADDING_USER';
export const ADDING_USER_FAILED = 'ADDING_USER_FAILED';
export const ADDED_USER = 'ADDED_USER';
export const CLEAR_ADDED_USER = 'CLEAR_ADDED_USER';
export const IMPERSONATING = 'IMPERSONATING';
export const IMPERSONATING_SAVE_TOKEN = 'IMPERSONATING_SAVE_TOKEN';
export const IMPERSONATED = 'IMPERSONATED';
export const IMPERSONATE_FAILED = 'IMPERSONATE_FAILED';
export const IMPERSONATE_STOPPED = 'IMPERSONATE_STOPPED';

export const GETTING_VISITOR_COUNT = 'GETTING_VISITOR_COUNT';
export const GOT_VISITOR_COUNT = 'GOT_VISITOR_COUNT';
export const GOT_REAL_TIME_COUNT = 'GOT_REAL_TIME_COUNT';
export const GOT_TOTAL_VISITS_COUNT = 'GOT_TOTAL_VISITS_COUNT';
export const GOT_CHOSEN_DESTINATIONS = 'GOT_CHOSEN_DESTINATIONS';

export const LOADING_CLIENTS = 'LOADING_CLIENTS';
export const LOADED_CLIENTS = 'LOADED_CLIENTS';
export const ADDING_CLIENT = 'ADDING_CLIENT';
export const ADDED_CLIENT = 'ADDED_CLIENT';
export const UPDATING_CLIENT = 'UPDATING_CLIENT';
export const UPDATED_CLIENT = 'UPDATED_CLIENT';
export const CLEAR_ADDED_CLIENT = 'CLEAR_ADDED_CLIENT';

export const LOADING_LOCATIONS = 'LOADING_LOCATIONS';
export const LOADED_LOCATIONS = 'LOADED_LOCATIONS';
export const ADDING_LOCATION = 'ADDING_LOCATION';
export const ADDED_LOCATION = 'ADDED_LOCATION';
export const UPDATING_LOCATION = 'UPDATING_LOCATION';
export const UPDATED_LOCATION = 'UPDATED_LOCATION';
export const CLEAR_ADDED_LOCATION = 'CLEAR_ADDED_LOCATION';
export const CLEAR_LOCATION_STATE = 'CLEAR_LOCATION_STATE';
export const LOADING_WHITELIST = 'LOADING_WHITELIST'
export const LOADED_WHITELIST = 'LOADED_WHITELIST'
export const CLEAR_WHITELIST = 'CLEAR_WHITELIST'

export const GETTING_CITATIONS = 'GETTING_CITATIONS';
export const GOT_CITATIONS = 'GOT_CITATIONS';
export const GETTING_DAILY_SCANS = 'GETTING_DAILY_SCANS';
export const GOT_DAILY_SCANS = 'GOT_DAILY_SCANS';
export const GOT_SCANS = 'GOT_SCANS';
export const GETTING_ALL_CITATIONS = 'GETTING_ALL_CITATIONS';
export const GOT_ALL_CITATIONS = 'GOT_ALL_CITATIONS';
export const SET_CITATION = 'SET_CITATION';
export const CLEAR_CITATION = 'CLEAR_CITATION';
export const GETTING_DISPUTES = 'GETTING_DISPUTES';
export const GOT_DISPUTES = 'GOT_DISPUTES';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const GETTING_ALL_PAYMENTS = 'GETTING_ALL_PAYMENTS';
export const GOT_ALL_PAYMENTS = 'GOT_ALL_PAYMENTS';

export const LOADING_VEHICLE_ACTIVITIES = 'LOADING_VEHICLE_ACTIVITIES'
export const LOADED_VEHICLE_ACTIVITIES = 'LOADED_VEHICLE_ACTIVITIES'

export const LOADING_TENANTS = 'LOADING_TENANTS'
export const LOADED_TENANTS = 'LOADED_TENANTS'
export const ADDED_TENANT = 'ADDED_TENANT'
export const ADDING_TENANT = 'ADDING_TENANT'
export const ADDING_TENANT_FAILED = 'ADDING_TENANT_FAILED'