import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import moment from 'moment-timezone';
import emailjs from '@emailjs/browser';

//let functions = firebase.functions();

export const getAllCitations = (startDateInput, endDateInput) => {
  return async (dispatch, getState) => {
    dispatch({type: actionConstants.GETTING_ALL_CITATIONS})
    let citations
    let query
    let startDate = moment(startDateInput).toISOString()
    let endDate = moment(endDateInput).toISOString()
    const getAllCitationsQuery = firebase.functions().httpsCallable('bigQueryRawSql')
    if (getState().user.user.type === 'super') {
      query = "SELECT t.data as data, JSON_VALUE(t.path_params, '$.clientId') as clientId, JSON_VALUE(t.path_params, '$.locationId') as locationId, t.document_id as id  FROM `firestore_tickets.tickets_raw_latest` t where 1=1 "
    } else {
      query = "SELECT t.data as data, JSON_VALUE(t.path_params, '$.clientId') as clientId, JSON_VALUE(t.path_params, '$.locationId') as locationId, t.document_id as id  FROM `firestore_tickets.tickets_raw_latest` t where JSON_VALUE(t.path_params, '$.clientId') = '" + getState().client.clients[0].id + "' "
    }
    if (startDateInput && endDateInput) {
      query = query + ' AND JSON_VALUE(t.data, "$.created_at") > "' + startDate + '" AND JSON_VALUE(t.data, "$.created_at") < "' + endDate + '"'
    }
    citations = await getAllCitationsQuery({query}).then(result => result).catch(error => console.log(error))
    let formattedCitations
    let finalCitations = []
    if (citations?.data) {
      formattedCitations = JSON.parse(citations.data)
    }
    if (formattedCitations?.length > 0) {
      formattedCitations[0].forEach(row => {
        let rowData = JSON.parse(row.data)
        finalCitations.push({...rowData, client: row.clientId, location: row.locationId, id: row.id})
        // if (!rowData.dispute || rowData.dispute?.status !== 'accepted') {
        //   finalCitations.push({...rowData, client: row.clientId, location: row.locationId, id: row.id})
        // }
      })
    }
    dispatch({type: actionConstants.GOT_ALL_CITATIONS, payload: finalCitations})
    return finalCitations
  }
}

export const getAllCitationsByViolator = (startDateInput, endDateInput) => {
  return async (dispatch, getState) => {
    dispatch({type: actionConstants.GETTING_ALL_CITATIONS})
    let citations
    let query
    let startDate = moment(startDateInput).toISOString()
    let endDate = moment(endDateInput).toISOString()
    const getAllCitationsQuery = firebase.functions().httpsCallable('bigQueryRawSql')
    
    if (getState().user.user.type === 'super') {
      query = `
        SELECT 
          JSON_VALUE(t.data, '$.license_plate') as license_plate,
          ARRAY_AGG(
            STRUCT(
              t.data as data, 
              JSON_VALUE(t.path_params, '$.clientId') as clientId, 
              JSON_VALUE(t.path_params, '$.locationId') as locationId, 
              t.document_id as id
            )
          ) as citations
        FROM \`firestore_tickets.tickets_raw_latest\` t 
        WHERE 1=1 `
    } else {
      query = `
        SELECT 
          JSON_VALUE(t.data, '$.license_plate') as license_plate,
          ARRAY_AGG(
            STRUCT(
              t.data as data, 
              JSON_VALUE(t.path_params, '$.clientId') as clientId, 
              JSON_VALUE(t.path_params, '$.locationId') as locationId, 
              t.document_id as id
            )
          ) as citations
        FROM \`firestore_tickets.tickets_raw_latest\` t 
        WHERE JSON_VALUE(t.path_params, '$.clientId') = '${getState().client.clients[0].id}' `
    }
    
    if (startDateInput && endDateInput) {
      query = query + ' AND JSON_VALUE(t.data, "$.created_at") > "' + startDate + '" AND JSON_VALUE(t.data, "$.created_at") < "' + endDate + '"'
    }
    
    // Add GROUP BY clause for license plate
    query = query + ' GROUP BY license_plate'
    
    citations = await getAllCitationsQuery({query}).then(result => result).catch(error => console.log(error))
    
    let formattedCitations
    let finalCitations = []
    
    if (citations?.data) {
      formattedCitations = JSON.parse(citations.data)
    }
    
    if (formattedCitations?.length > 0) {
      formattedCitations[0].forEach(group => {
        const licensePlate = group.license_plate
        const citationsForLicense = group.citations.map(citation => {
          const rowData = JSON.parse(citation.data)
          return {
            ...rowData, 
            client: citation.clientId, 
            location: citation.locationId, 
            id: citation.id
          }
        })
        
        finalCitations.push({
          license_plate: licensePlate,
          citations: citationsForLicense,
          client: citationsForLicense[0].client,
          total_count: citationsForLicense.length
        })
      })
    }
    
    dispatch({type: actionConstants.GOT_ALL_CITATIONS, payload: finalCitations})
    return finalCitations
  }
}

export const getAllPayments = (startDateInput, endDateInput) => {
  return async (dispatch, getState) => {
    dispatch({type: actionConstants.GETTING_ALL_PAYMENTS})
    let payments
    let query
    let startDate = moment(startDateInput).toISOString()
    let endDate = moment(endDateInput).toISOString()
    const getAllPaymentsQuery = firebase.functions().httpsCallable('bigQueryRawSql')
    if (getState().user.user.type === 'super') {
      query = "SELECT t.data as data, JSON_VALUE(t.path_params, '$.clientId') as clientId, JSON_VALUE(t.path_params, '$.locationId') as locationId, t.document_id as id  FROM `firestore_tickets.tickets_raw_latest` t where 1=1 "
    } else {
      query = "SELECT t.data as data, JSON_VALUE(t.path_params, '$.clientId') as clientId, JSON_VALUE(t.path_params, '$.locationId') as locationId, t.document_id as id  FROM `firestore_tickets.tickets_raw_latest` t where JSON_VALUE(t.path_params, '$.clientId') = '" + getState().client.clients[0].id + "' "
    }
    if (startDateInput && endDateInput) {
      query = query + ' AND JSON_VALUE(t.data, "$.payment_on") > "' + startDate + '" AND JSON_VALUE(t.data, "$.payment_on") < "' + endDate + '"'
    }
    payments = await getAllPaymentsQuery({query}).then(result => result).catch(error => console.log(error))
    let formattedPayments
    let finalPayments = []
    if (payments?.data) {
      formattedPayments = JSON.parse(payments.data)
    }
    if (formattedPayments?.length > 0) {
      formattedPayments[0].forEach(row => {
        let rowData = JSON.parse(row.data)
        finalPayments.push({...rowData, client: row.clientId, location: row.locationId, id: row.id})
        // if (!rowData.dispute || rowData.dispute?.status !== 'accepted') {
        //   finalCitations.push({...rowData, client: row.clientId, location: row.locationId, id: row.id})
        // }
      })
    }
    dispatch({type: actionConstants.GOT_ALL_PAYMENTS, payload: finalPayments})
    return finalPayments
  }
}

/**
 * Get single citation
 * 
 * @param {*} citation 
 * @returns 
 */
export const getSingleCitation = (violationNumber) => {
  return async (dispatch, getState) => {
    return firebase
      .firestore()
      .collectionGroup('tickets')
      .where('violation_number', '==', violationNumber)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          let doc = querySnapshot.docs[0]
          let client = doc.ref.parent.parent.parent.parent.id
          dispatch({ type: actionConstants.SET_CITATION, payload: {id: doc.id, ...doc.data(), location: doc.data().location, client: client}})
        }
      })
  }
}

/**
 * Mark ticket/citation as paid
 * 
 * @param {*} citation 
 * @returns 
 */
export const markAsPaid = citation => {
  return async (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('clients')
      .doc(citation.client)
      .collection('locations')
      .doc(citation.location)
      .collection('tickets')
      .doc(citation.id)
      .update({
        status: "paid",
        payment_on: moment.utc().toISOString(),
        payment: {
          type: 'other',
          amount: parseFloat(citation.violations_total).toFixed(2),
          reference: "Manual payment",
          user: getState().user.user.id,
        }})
      .then(doc => {})
      .catch(error => console.log(error))
  }
}

/**
 * Accept disputed citation
 * 
 * @param {*} citation 
 * @returns 
 */
export const acceptDispute = (citation, accept_note = null) => {
  return (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('clients')
      .doc(citation.client)
      .collection('locations')
      .doc(citation.location)
      .collection('tickets')
      .doc(citation.id)
      .update({
        dispute: {
          ...citation.dispute,
          accepted_on: moment().utc().toISOString(),
          accepted_by: getState().user.user.id,
          accepted_note: accept_note,
          status: 'accepted'
        },
        total: 0,
        status: 'closed'
      })
      .then(() => {
        dispatch(getAllCitations())
        emailjs.send("service_cghbh4g","template_xwyylde",{
          violation_number: citation.violation_number,
          to_email: citation.dispute.email,
          accept_note: accept_note || '',
        }, 'Bdnrcb9nkMG24q1Uv');
      })
    }
}

/**
 * Void a citation
 * 
 * @param {*} citation 
 * @returns 
 */
export const voidCitation = (citation, void_note = null) => {
  return (dispatch, getState) => {
    let dispute = null
    if (citation?.dispute) {
      dispute = {
        ...citation.dispute,
        accepted_on: moment().utc().toISOString(),
        accepted_by: getState().user.user.id,
        status: 'accepted'
      }
    }
    return firebase
      .firestore()
      .collection('clients')
      .doc(citation.client)
      .collection('locations')
      .doc(citation.location)
      .collection('tickets')
      .doc(citation.id)
      .update({
        dispute: dispute || null,
        total: 0,
        status: 'void',
        voided_on: moment().utc().toISOString(),
        voided_by: getState().user.user.id,
        voided_note: void_note
      })
      .then(() => {
        dispatch(getAllCitations())
      })
    }
}

/**
 * Reject disputed citation
 * 
 * @param {*} citation 
 * @param {*} adjustmentNote 
 * @returns 
 */
export const rejectDispute = (citation, adjustmentNote) => {
  return (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('clients')
      .doc(citation.client)
      .collection('locations')
      .doc(citation.location)
      .collection('tickets')
      .doc(citation.id)
      .update({
        dispute: {
          ...citation.dispute,
          rejected_on: moment().utc().toISOString(),
          rejected_by: getState().user.user.id,
          adjustment_note: adjustmentNote || null,
          status: 'rejected'
        }
      })
      .then(() => {
        dispatch(getAllCitations())
        emailjs.send("service_cghbh4g","template_361nh82",{
          violation_number: citation.violation_number,
          to_email: citation.dispute.email,
          balance: citation.violations_total,
          adjustment_note: adjustmentNote || '',
          link: 'https://payment.enforceplus.com/violations/' + citation.vehicle,
        }, 'Bdnrcb9nkMG24q1Uv');
      })
    
  }
}

/**
 * 
 * @param {*} citation 
 * @param {*} newAmount 
 * @param {*} adjustmentNote 
 * @returns 
 */
export const adjustDispute = (citation, newAmount, adjustmentNote) => {
  if (!newAmount || isNaN(newAmount)) {
    newAmount = 0
  }
  return (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('clients')
      .doc(citation.client)
      .collection('locations')
      .doc(citation.location)
      .collection('tickets')
      .doc(citation.id)
      .update({
        dispute: {
          ...citation.dispute,
          adjusted_on: moment().utc().toISOString(),
          adjusted_by: getState().user.user.id,
          original_amount: citation.violations_total,
          adjusted_discount: parseFloat(citation.violations_total) - parseFloat(newAmount),
          status: 'adjusted',
          adjustment_note: adjustmentNote || null
        },
        early_bird_percentage: 0,
        total: parseFloat(parseFloat(newAmount)).toFixed(2),
        penalty_start_at: moment.utc().add(1, "year").toISOString()
      })
      .then(() => {
        dispatch(getAllCitations())
        emailjs.send("service_cghbh4g","template_hiucmu3",{
          violation_number: citation.violation_number,
          to_email: citation.dispute.email,
          adjusted_discount: parseFloat(parseFloat(citation.violations_total) - parseFloat(newAmount)).toFixed(2),
          new_balance: parseFloat(newAmount).toFixed(2),
          adjustment_note: adjustmentNote || '',
          link: 'https://payment.enforceplus.com/violations/' + citation.vehicle,
        }, 'Bdnrcb9nkMG24q1Uv');
      })
  }
}

/**
 * Clear citation
 * 
 * @param {*} citation 
 * @returns 
 */
export const clearCitation = () => {
  return async (dispatch, getState) => {
    dispatch({ type: actionConstants.SET_CITATION, payload: null})
  }
}
