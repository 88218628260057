import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import SidebarNavItemOpener from "./SidebarNavItemOpener";
import SidebarNavSubItem from "./SidebarNavSubItem";

import { accessCheck } from "../../../utils/tools";

const sidebarNavItems = [
  // {
  //   title: "Add New Post",
  //   htmlBefore: '<i class="material-icons">note_add</i>',
  //   to: "/add-new-post",
  // },
  {
    title: "Forms & Components",
    htmlBefore: '<i className="material-icons">view_module</i>',
    to: "/components-overview",
  },
  {
    title: "Tables",
    htmlBefore: '<i className="material-icons">table_chart</i>',
    to: "/tables",
  },
  // {
  //   title: "Errors",
  //   htmlBefore: '<i class="material-icons">error</i>',
  //   to: "/errors",
  // },
];

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)
    const isSettingsSubPage = window.location.href.indexOf('showing-defaults') !== -1 || window.location.href.indexOf('calendar-link-settings') !== -1
    this.state = {
      navItems: sidebarNavItems,
      showSettingsSubItems: isSettingsSubPage,
    };
    if (props.user.type === 'tenant') {
      this.locationName = props.locations.find(loc => loc.id=props.user.location)?.name
    }
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {this.props.user.type === 'tenant' && (
            <SidebarNavItem
              item={{
                title: this.locationName ?? "Permitted Vehicles",
                //htmlBefore: '<i class="material-icons">home</i>',
                to: "/tenant-vehicles",
              }}
            />
          )}
          {accessCheck(this.props.user, 'dashboard') && (
            <SidebarNavItem
              item={{
                title: "Dashboard",
                //htmlBefore: '<i class="material-icons">home</i>',
                to: "/dashboard",
              }}
            />
          )}
          {accessCheck(this.props.user, 'locations') && (
            <SidebarNavItem
              item={{
                title: "Locations",
                //htmlBefore: '<i class="material-icons">home</i>',
                to: "/locations",
              }}
            />
          )}
          {accessCheck(this.props.user, 'citations') && (
            <SidebarNavItem
              item={{
                title: "Citations",
                //htmlBefore: '<i class="material-icons">calendar_month</i>',
                to: "/citations",
              }}
            />
          )}
          {/* {accessCheck(this.props.user, 'citations') && (
            <SidebarNavItem
              item={{
                title: "Violators",
                //htmlBefore: '<i class="material-icons">calendar_month</i>',
                to: "/violators",
              }}
            />
          )} */}
          {accessCheck(this.props.user, 'payments') && (
            <SidebarNavItem
              item={{
                title: "Payments",
                //htmlBefore: '<i class="material-icons">calendar_month</i>',
                to: "/payments",
              }}
            />
          )}
          {accessCheck(this.props.user, 'citations') && (
            <SidebarNavItem
              item={{
                title: "Warnings",
                //htmlBefore: '<i class="material-icons">calendar_month</i>',
                to: "/warnings",
              }}
            />
          )}
          {accessCheck(this.props.user, 'users') && (
            <SidebarNavItem
              item={{
                title: "Users",
                //htmlBefore: '<i class="material-icons">calendar_month</i>',
                to: "/users",
              }}
            />
          )}
          {accessCheck(this.props.user, 'clients') && (
            <SidebarNavItem
              item={{
                title: "Clients",
                //htmlBefore: '<i class="material-icons">calendar_month</i>',
                to: "/clients",
              }}
            />
          )}
          {accessCheck(this.props.user, 'activities') && (
            <SidebarNavItem
              item={{
                title: "Activities",
                //htmlBefore: '<i class="material-icons">error</i>',
                to: "/activities",
              }}
            />
          )}  
          {accessCheck(this.props.user, 'settings') && (
            <SidebarNavItem
              item={{
                title: "Settings",
                //htmlBefore: '<i class="material-icons">error</i>',
                to: "/settings",
              }}
            />
          )}  
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
