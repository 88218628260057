/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect,
  FormInput,
  FormCheckbox,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "shards-react";
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import EventNoteIcon from '@mui/icons-material/EventNote';
import TimePicker from 'react-bootstrap-time-picker';
import { accessCheck } from "../../utils/tools";
import { WEB_PAYMENT_URL } from "../../environmentVariables";
import PageTitle from "../../components/common/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { CONSTANTS } from "../../constants";
import "react-datepicker/dist/react-datepicker.css";
import { InsertInvitationOutlined } from "@mui/icons-material";
import { floor } from "lodash";
import ActivityIndicator from "../../components/common/ActivityIndicator";
import Pagination from "../../components/common/Pagination";

const TenantVehicles = (props) => {
  const [location, setLocation] = useState(props.locations.find(location => location.id === props.user.location));
  const [whitelist, setWhitelist] = useState([]);
  const [sortBy, setSortBy] = useState('licenseAsc')
  const [search, setSearch] = useState('')
  const [showAddVehicleModal, setShowAddVehicleModal] = useState(false)
  const [licensePlate, setLicensePlate] = useState('')
  const [licensePlateState, setLicensePlateState] = useState(location.state)
  const [startDate, setStartDate] = useState(moment().toDate())
  const [startTime, setStartTime] = useState(moment().format("HH:mm"))
  const [howLong, setHowLong] = useState('today')
  const [untilADate, setUntilADate] = useState(false)
  const [endDate, setEndDate] = useState(moment().add(1, 'days').toDate())
  const [page, setPage] = useState(1)
  const [pageSize] = useState(25)
  const [zone, setZone] = useState(props.locations.find(location => location.id === props.user.location)?.zones?.find(zone => zone.id === props.user.zone)?.id)
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [filter, setFilter] = useState('active')


  useEffect(() => {
    props.getLocationWhitelist(location)
    document.title = 'Tenant vehicles: EnforcePlus admin';
    //!accessCheck(props.user, 'locations') && (window.location.href = '/')
    return (() => props.clearWhitelist())
  }, [])

  useEffect(() => {
    setWhitelist(props.whitelist.filter(vehicle => vehicle.tenant === props.user.id))
  }, [props.whitelist])

  const filterWhitelist = () => {
    if (whitelist?.length > 0) {
      let sortedWhitelist = [...whitelist];
      if (filter === 'active') {
        sortedWhitelist = sortedWhitelist.filter(item => moment(item.expiry_time).isAfter(moment()))
      }
      if (filter === 'expired') {
        sortedWhitelist = sortedWhitelist.filter(item => moment(item.expiry_time).isBefore(moment()))
      }
      if (search !== '') {
        sortedWhitelist = sortedWhitelist.filter(item => (
          item.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1
        ))
      }
      
      if (sortBy === 'startdateDesc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.start_time && b.start_time && moment(a.start_time).isBefore(moment(b.start_time)) ? 1 : -1);
      }
      if (sortBy === 'startdateAsc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.start_time && b.start_time && moment(a.start_time).isBefore(moment(b.start_time)) ? -1 : 1);
      }
      if (sortBy === 'expiresDesc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.expiry_time && b.expiry_time && moment(a.expiry_time).isBefore(moment(b.expiry_time)) ? 1 : -1);
      }
      if (sortBy === 'expiresAsc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.expiry_time && b.expiry_time && moment(a.expiry_time).isBefore(moment(b.expiry_time)) ? -1 : 1);
      }
      if (sortBy === 'licenseAsc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.license_plate.localeCompare(b.license_plate));
      }
      if (sortBy === 'licenseDesc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => b.license_plate.localeCompare(a.license_plate));
      }
      return sortedWhitelist
    }
    
  }

  const showLimitWarning = () => {
    alert('Sorry, you are at your maximum total permitted vehicles. You may need to delete one. Set the filter to All to view all your vehicles')
  }

  const whitelistAction = (item, action) => {
    if (action === 'remove_vehicle') {
      let confirm = window.confirm('Are you sure you want to remove this vehicle from the list?')
      if (confirm) {
        props.removeFromWhitelist(location, item)
      }
    }
    if (action === 'edit_vehicle') {
      editVehicle(item)
    }
  }

  const editVehicle = (item) => {
    setSelectedVehicle(item)
    setLicensePlate(item.license_plate)
    setLicensePlateState(item.license_plate_state)
    setStartDate(moment(item.start_time).toDate())
    setStartTime(moment(item.start_time).format("HH:mm"))
    if (moment().add(1, 'year') < moment(item.expiry_time)) {
      setHowLong('untilRemoved')
      setUntilADate(false)
    } else {
      setHowLong('today')
      setUntilADate(true)
    }
    setEndDate(moment(item.expiry_time).toDate())
    setZone(item.zone)
    setShowAddVehicleModal(true)
  }

  const isValid = () => {
    let isValid = true
    if (licensePlate?.length < 3) {
      isValid = false
    }
    return isValid
  }

  const calculateTimeFromNumbers = (numbers) => {
    let hours = Math.floor(numbers / 3600)
    let minutes = floor((numbers - (hours * 3600)) / 60)
    return {hours, minutes}
  }

  const addVehicleToWhitelist = () => {
    let data = {
      license_plate: licensePlate,
      license_plate_state: licensePlateState,
      zone: zone || null,
      tenant: props.user.id,
      source: props.user.name,
    }
    let adjustedStartDate = moment(startDate)
    adjustedStartDate = adjustedStartDate.set('hour', calculateTimeFromNumbers(startTime).hours)
    adjustedStartDate = adjustedStartDate.set('minute', calculateTimeFromNumbers(startTime).minutes)
    adjustedStartDate = adjustedStartDate.set('second', 0)

    let adjustedEndDate = moment(endDate)
    if (!untilADate) {
      if (howLong === 'today') {
        adjustedEndDate = moment(adjustedStartDate)
      }
      if (howLong === 'untilRemoved') {
        adjustedEndDate = moment('9990-12-31 00:00:00')
      }
      if (howLong !== 'today' && howLong !== 'untilRemoved') {
        adjustedEndDate = moment(adjustedStartDate).add(1, howLong)
      }
    }
    adjustedEndDate = adjustedEndDate.set('hour', 23)
    adjustedEndDate = adjustedEndDate.set('minute', 59)
    adjustedEndDate = adjustedEndDate.set('second', 59)

    data.start_time = moment(adjustedStartDate).utc().toISOString()
    data.expiry_time = moment(adjustedEndDate).utc().toISOString()
    props.addVehicleToWhitelist(location, data)
    setLicensePlate('')
    setLicensePlateState(location.state)
    setStartDate(moment().toDate())
    setStartTime(moment().format("HH:mm"))
    setHowLong('today')
    setUntilADate(false)
    setEndDate(moment().add(1, 'days').toDate())
    setZone(null)
    setSelectedVehicle(null)
    setShowAddVehicleModal(false)
  }

  const editVehicleOnWhitelist = () => {
    let data = {
      license_plate: licensePlate,
      license_plate_state: licensePlateState,
      zone: zone || null,
    }
    let adjustedStartDate = moment(startDate)
    adjustedStartDate = adjustedStartDate.set('hour', calculateTimeFromNumbers(startTime).hours)
    adjustedStartDate = adjustedStartDate.set('minute', calculateTimeFromNumbers(startTime).minutes)
    adjustedStartDate = adjustedStartDate.set('second', 0)

    let adjustedEndDate = moment(endDate)
    if (!untilADate) {
      if (howLong === 'today') {
        adjustedEndDate = moment(adjustedStartDate)
      }
      if (howLong === 'untilRemoved') {
        adjustedEndDate = moment('9990-12-31 00:00:00')
      }
      if (howLong !== 'today' && howLong !== 'untilRemoved') {
        adjustedEndDate = moment(adjustedStartDate).add(1, howLong)
      }
    }
    adjustedEndDate = adjustedEndDate.set('hour', 23)
    adjustedEndDate = adjustedEndDate.set('minute', 59)
    adjustedEndDate = adjustedEndDate.set('second', 59)

    data.start_time = moment(adjustedStartDate).utc().toISOString()
    data.expiry_time = moment(adjustedEndDate).utc().toISOString()
    data.space_number = "2"
    data.source = props.user.name
    props.editVehicleOnWhitelist(selectedVehicle, location, data)
    setSelectedVehicle(null)
    setLicensePlate('')
    setLicensePlateState(location.state)
    setStartDate(moment().toDate())
    setStartTime(moment().format("HH:mm"))
    setHowLong('today')
    setUntilADate(false)
    setEndDate(moment().add(1, 'days').toDate())
    setZone(null)
    setSelectedVehicle(null)
    setShowAddVehicleModal(false)
  }

  const closeVehicleModal = () => {
    setSelectedVehicle(null)
    setLicensePlate('')
    setLicensePlateState(location.state)
    setStartDate(moment().toDate())
    setStartTime(moment().format("HH:mm"))
    setHowLong('today')
    setUntilADate(false)
    setEndDate(moment().add(1, 'days').toDate())
    setZone(null)
    setSelectedVehicle(null)
    setShowAddVehicleModal(false)
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      {/* First Row of Posts */}
      <Row>
        <Col>
          <Row noGutters className="page-header py-4">
            <PageTitle xs="12" sm="6" title={"Permitted vehicles: " + location.name} className="text-sm-left mb-3" />
            <Col xs="12" sm="6" className='text-right'>
              <Row>
                <Col xs="1"></Col>
                <Col className='text-right pl-0'>
                  <Button
                    theme="accent"
                    onClick={() => {
                      if (parseInt(props.user.vehicle_limit) - whitelist.filter(item => moment(item.expiry_time).isAfter(moment())).length > 0) {
                        setShowAddVehicleModal(true)
                      } else {
                        showLimitWarning()
                      }
                    }}
                  >Add vehicle</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {props.loadingWhitelist ? (
            <Row>
              <Col className={'text-center'}>
                <ActivityIndicator />
              </Col>
            </Row>
          ) : whitelist?.length === 0 ? (
            <>
              <Card small className="mb-4 mt-4">
                <Row>
                  <Col xs={12} style={{paddingTop: 48, paddingBottom: 32, textAlign: 'center'}}>
                    <span style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 12}}>Get started and add your vehicles</span><br />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} style={{paddingBottom: 64, textAlign: 'center'}}>
                    <Button
                      theme="accent"
                      onClick={() => setShowAddVehicleModal(true)}
                    >Add vehicle</Button>
                  </Col>
                </Row>
              </Card>
            </>
          ) : (
            <>
              <Card small className="mb-4 mt-4">
                <CardHeader>
                  <Row>
                    <Col xs={6}>
                      <span style={{fontSize: 12}}>{
                      !!(parseInt(props.user.vehicle_limit) - whitelist.filter(item => moment(item.expiry_time).isAfter(moment())).length > 0) ?
                        ('You can add ' + (parseInt(props.user.vehicle_limit) - whitelist.filter(item => moment(item.expiry_time).isAfter(moment())).length) + ' more permitted vehicle' + ((parseInt(props.user.vehicle_limit) - whitelist.filter(item => moment(item.expiry_time).isAfter(moment())).length > 1) ? 's' : '')) :
                        ('You cannot add any more permitted vehicles')
                      }</span>
                    </Col>
                    <Col>
                      <FormSelect value={filter} id="feFilter" onChange={
                        event => { 
                          setFilter(event.target.value);
                        }}>
                        <option value={'active'}>Active</option>
                        <option value={'expired'}>Expired</option>
                        <option value={'all'}>All</option>
                      </FormSelect>
                    </Col>
                    <Col xs={3} className='text-right pl-0'>
                      <FormInput type={'text'} className={'form-control'} placeholder={'Search'} value={search} onChange={event => setSearch(event.target.value)} />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table mb-0">
                    <thead className="bg-none">
                      <tr>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'licenseAsc' ? 'licenseAsc' : 'licenseDesc')} style={{cursor: 'pointer'}}>License plate</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'idAsc' ? 'idAsc' : 'idDesc')} style={{cursor: 'pointer'}}>Source</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'startdateDesc' ? 'startdateDesc' : 'startdateAsc')} style={{cursor: 'pointer'}}>Start time</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'expiresDesc' ? 'expiresDesc' : 'expiresAsc')} style={{cursor: 'pointer'}}>Expires</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'idAsc' ? 'idAsc' : 'idDesc')} style={{cursor: 'pointer'}}>Zone</div>
                        </th>
                        {/* <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'amountAsc' ? 'amountAsc' : 'amountDesc')} style={{cursor: 'pointer'}}>Amount paid</div>
                        </th> */}
                        <th scope="col" className="border-0">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.whitelist && filterWhitelist()?.length > 0 && filterWhitelist()?.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize)?.map(item => {
                        return (
                          <tr key={item.id}>
                            <td style={{paddingTop: 24}}>
                              {item.license_plate}
                            </td>
                            <td style={{paddingTop: 24}} className="d-none d-md-table-cell">
                              {item.source}
                            </td>
                            <td style={{paddingTop: 24}}>
                              {moment(item.start_time).format('MM/DD/Y h:mm a')}
                            </td>
                            <td style={{paddingTop: 24}}>
                              <span style={{color: moment(item.expiry_time).isBefore(moment()) ? 'red' : 'auto'}}>
                                {moment(item.expiry_time).diff(moment(), 'year') <= 0 ? (
                                  moment(item.expiry_time).format('MM/DD/Y h:mm a')
                                ) : (
                                  'Permit'
                                )}
                              </span>
                            </td>
                            <td style={{paddingTop: 24}}>
                              {location.zones?.find(zone => zone.id === item.zone)?.name || 'All zones'}
                            </td>
                            {/* <td style={{paddingTop: 24}} className="d-none d-md-table-cell">
                              
                            </td> */}
                            <td className="text-right">
                              <FormSelect id="feInputState" onChange={event => {whitelistAction(item, event.target.value); event.target.value = ''}}>
                                <option value={''}>Actions</option>
                                <option value={'edit_vehicle'}>Edit vehicle</option>
                                <option value={'remove_vehicle'}>Remove vehicle</option>           
                              </FormSelect>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Pagination
                data={filterWhitelist()}
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}      
              />
            </>
          )}
          
        </Col>
      </Row>
      <Modal size={'md'} open={showAddVehicleModal}>
        <ModalHeader toggle={() => closeVehicleModal()}>{selectedVehicle?.id ? 'Edit' : 'Add'} a permitted vehicle</ModalHeader>
        <ModalBody className={'p-3 pt-4 pb-4'}>
          <Row form className={'mb-2'}>
            <Col xs={7} className="form-group">
              <label className="form-label-custom">Enter the license plate</label>
              <FormInput type='text' value={licensePlate} onChange={(event) => setLicensePlate(event.target.value.toUpperCase().replace(/\s/gim, ''))} />
            </Col>
            <Col xs={5} className="form-group">
              <label className="form-label-custom">Enter the State</label>
              <FormSelect value={licensePlateState} onChange={(event) => setLicensePlateState(event.target.value)}>
                {CONSTANTS.US_STATES.map(state => (
                  <option value={state.abbreviation}>{state.name}</option>
                ))}
                {CONSTANTS.CA_STATES.map(state => (
                  <option value={state.abbreviation}>CA - {state.name}</option>
                ))}
              </FormSelect>
            </Col>
          </Row>
          <Row form className={'mb-2'}>
            <Col xs={7} className="form-group">
              <label className="form-label-custom">When would you like to add the vehicle</label>
              <InputGroup seamless style={{borderRadius: 3}}>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <span style={{fontSize: 18}}><EventNoteIcon fontSize={'inherit'} /></span>
                  </InputGroupText>
                </InputGroupAddon>
                <DatePicker
                  className={'form-control'}
                  style={{width: '100%', borderRadius: '3px !important', border: 'none !important'}}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  todayButton="Today"
                  />
              </InputGroup>
            </Col>
            <Col xs={5} className="form-group">
              <label className="form-label-custom">&nbsp;</label>
              <TimePicker start="00:00" end="23:55" step={5} value={startTime} onChange={(time) => setStartTime(time)} />
            </Col>
          </Row>
          {!untilADate && (
            <Row form className={'mb-2'}>
              <Col xs={7} className="form-group">
                <label className="form-label-custom">How long should the vehicle be valid for?</label>
                <FormSelect value={howLong} onChange={(event) => setHowLong(event.target.value)}>
                  <option value={'today'}>Today only</option>
                  <option value={'week'}>1 week</option>
                  <option value={'month'}>1 month</option>
                  <option value={'year'}>1 year</option>
                  <option value={'untilRemoved'}>Until removed</option>
                </FormSelect>
              </Col>
              <Col xs={5} className="form-group pt-1">
                <label className="form-label-custom">&nbsp;</label>
                <FormCheckbox checked={untilADate} onChange={() => setUntilADate(!untilADate)}>until a date</FormCheckbox>
              </Col>
            </Row>
          )}
          {untilADate && (
            <Row form className={'mb-2'}>
              <Col xs={7} className="form-group">
                <label className="form-label-custom">Select an end date</label>
                <InputGroup seamless style={{borderRadius: 3}}>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <span style={{fontSize: 18}}><EventNoteIcon fontSize={'inherit'} /></span>
                    </InputGroupText>
                  </InputGroupAddon>
                  <DatePicker
                    className={'form-control'}
                    style={{width: '100%', borderRadius: '3px !important', border: 'none !important'}}
                    selected={endDate}
                    minDate={startDate}
                    onChange={(date) => setEndDate(date)}
                    />
                </InputGroup>
              </Col>
              <Col xs={5} className="form-group pt-1">
                <label className="form-label-custom">&nbsp;</label>
                <FormCheckbox checked={untilADate} onChange={() => setUntilADate(!untilADate)}>until a date</FormCheckbox>
              </Col>
            </Row>  
          )}
        </ModalBody>
        <ModalFooter style={{display: 'block !important'}}>
          <Row fluid>
            <Col xs={6} className={'text-left'}>
              <Button
                theme="outline-secondary"
                onClick={() => closeVehicleModal()}
                className={'mr-2'}
              >Cancel</Button>
                  
            </Col>
            <Col xs={6} className={'text-right'}>
              <Button
                theme="accent"
                disabled={!isValid()}
                onClick={() => selectedVehicle?.id ? editVehicleOnWhitelist() : addVehicleToWhitelist()}
              >Save</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>            
    </Container>
  );
}

export default TenantVehicles;
