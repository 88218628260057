const applicationId = 'sq0idp-zmDVkLzJCMFWTbszExs3Og'; // PROD
const locationId = 'LC39HE0XC4N09'; // PROD
const baseUrl = 'https://connect.squareup.com'; // PROD
// const applicationId = 'sandbox-sq0idb-TpcvnyVjdAI2Ayn2MuLG5A'; // SANDBOX
// const locationId = 'L7JDPFMGJRV2N'; // SANDBOX
// const baseUrl = 'https://connect.squareupsandbox.com' // SANDBOX

const scope = 'ORDERS_WRITE+PAYMENTS_WRITE+PAYMENTS_WRITE_IN_PERSON+MERCHANT_PROFILE_READ+CUSTOMERS_WRITE+PAYMENTS_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS';

const base64Encode = (str) => {
    if (str instanceof ArrayBuffer) {
        str = new Uint8Array(str);
    }
    return btoa(String.fromCharCode.apply(null, str))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

const generateRandomBytes = (length) => {
    return window.crypto.getRandomValues(new Uint8Array(length));
}

const sha256 = async (buffer) => {
    const encodedBuffer = new TextEncoder().encode(buffer);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', encodedBuffer);
    return new Uint8Array(hashBuffer);
}

// Use existing code verifier if available, otherwise generate new one
const codeVerifier = base64Encode(generateRandomBytes(32));

// Since sha256 is now async, we need to create the URL after the challenge is generated
export const generateUrl = async (state) => {
    // Set cookies with path and expiration
    document.cookie = `square-code-verifier=${codeVerifier}; path=/; max-age=3600`;
    document.cookie = `square-state=${state}; path=/; max-age=3600`;
    const codeChallenge = base64Encode(await sha256(codeVerifier));
    return `${baseUrl}/oauth2/authorize?client_id=${applicationId}&scope=${scope}&session=false&state=${state}&code_challenge=${codeChallenge}`;
}