/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
  FormInput,
} from "shards-react";
import { accessCheck } from "../../utils/tools";
import moment from 'moment-timezone'
import { WEB_PAYMENT_URL } from "../../environmentVariables";

import PageTitle from "../../components/common/PageTitle";
import Pagination from "../../components/common/Pagination";
import ActivityIndicator from "../../components/common/ActivityIndicator";
import DownloadButton from "../../components/common/DownloadButton";
import DatePicker from "react-datepicker";
import { startOfWeek, endOfWeek } from "date-fns";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

import "react-datepicker/dist/react-datepicker.css";

const Violators = (props) => {

  const [citations, setCitations] = useState([]);
  const [startDate, setStartDate] = useState(startOfWeek(new Date()))
  const [endDate, setEndDate] = useState(endOfWeek(new Date()))
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState(!searchParams.get("view") ? props.statusFilter : searchParams.get("view"));
  const [client, setClient] = useState('all');
  const [location, setLocation] = useState('all');
  const [sortBy, setSortBy] = useState('dateDesc')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize] = useState(100)
  const [endablePdfMode, setEndablePdfMode] = useState(false);

  const csvHeader = [
    'License plate',
    '#ID',
    'Date/Time',
    'Location',
    'Issued by',
    'Total',
    'Status'
  ]

  const generateFileData = () => {
    let citationsTemp = [...filterCitations()]
    let csvDataArray = citationsTemp.map(citation => {
      return citation.citations.map(c => {
        return [
          c.license_plate,
          c.violation_number,
          moment(c.created_at).format('MM/DD/Y h:mm a'),
          c.location_name,
          props.users.find(user => user.id === c.user)?.name,
          c.payment_on ? parseFloat(parseFloat(c.payment.amount) - parseFloat(c.service_fee)).toFixed(2) : parseFloat(c.total).toFixed(2),
          getCsvStatus(c)
        ]
      })
    })
    return [csvHeader, ...csvDataArray.flatMap(c => c)]
  }

  const generateList = () => {
    if (endablePdfMode) {
      return filterCitations()
    } else {
      return filterCitations().slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize);
    }
  }

  const getTargetElement = () => document.getElementById('content-id');

  const generateFilePdf = () => {
    const options = {
      method: 'save',
      resolution: Resolution.NORMAL,
      page: {
        margin: Margin.SMALL,
        format: 'A4',
        orientation: 'portrait',
      },
      canvas: {
        mimeType: 'image/jpeg',
        qualityRatio: 1
      },
      overrides: {
        pdf: {
          compress: true
        },
        canvas: {
          useCORS: true
        }
      },
      filename: 'enforceplus_citations.pdf'
    };

    setEndablePdfMode(true);
    setTimeout(() => {
      generatePDF(getTargetElement, options);
      setTimeout(() => {
        setEndablePdfMode(false);
      }, 1000);
    }, 1000);
  }

  useEffect(() => {
    props.getAllCitationsByViolator(startDate, endDate)
    document.title = 'Violators: EnforcePlus admin';
    !accessCheck(props.user, 'citations') && (window.location.href = '/')
    props.clearCitation()
  }, [])

  useEffect(() => {
    props.getAllCitationsByViolator(startDate, endDate)
  }, [startDate, endDate])

  useEffect(() => {
    setCitations(props.citations)
  }, [props.citations])

  // useEffect(() => {
  //   if (props.addedLocation) {
  //     setAddedLocation(true);
  //     setTimeout(() => {
  //       setAddedLocation(false)
  //       props.clearAddedLocation()
  //     }, 10000);
  //   }
  //   if (props.updatedLocation) {
  //     setUpdatedLocation(true);
  //     setTimeout(() => {
  //       setUpdatedLocation(false)
  //       props.clearAddedLocation()
  //     }, 10000);
  //   }
  // }, [props.addedLocation, props.updatedLocation])

  
  
  const filterCitations = () => {
    let sortedCitations = [...citations];
    if (search !== '') {
      sortedCitations = sortedCitations.filter(citation => (        
        citation.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1
        || citation.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1        
      ))
    }
    if (status !== 'all') {
      props.setStatusFilter(status)
      if (status === 'paid') {
        sortedCitations = sortedCitations.filter(citation => 
          citation.citations.some(c => c.payment_on)
        )
      } else if (status === 'closed') {
        sortedCitations = sortedCitations.filter(citation => 
          citation.citations.some(c => c.status === 'closed')
        )
      } else if (status === 'void') {
        sortedCitations = sortedCitations.filter(citation => 
          citation.citations.some(c => c.status === 'void')
        )
      } else if (status === 'unpaid') {
        sortedCitations = sortedCitations.filter(citation => 
          citation.citations.some(c => !c.payment_on && c.status !== 'closed' && c.status !== 'void')
        )
      } else {
        sortedCitations = sortedCitations.filter(citation => 
          citation.citations.some(c => c.dispute?.status === 'open' && !c.payment_on)
        )
      }
    }
    if (status === 'all') {
      props.setStatusFilter(status)
    }
    if (client !== 'all') {
      sortedCitations = sortedCitations.filter(citation => citation.client === client)
    }
    if (location !== 'all') {
      sortedCitations = sortedCitations.filter(citation => citation.citations.filter(c => c.location === location).length > 0)
    }
    if (sortBy === 'licenseAsc') {   
      sortedCitations = sortedCitations.sort((a, b) => a.license_plate.localeCompare(b.license_plate));
    }
    if (sortBy === 'licenseDesc') {      
      sortedCitations = sortedCitations.sort((a, b) => b.license_plate.localeCompare(a.license_plate));
    }
    if (sortBy === 'totalCountAsc') {      
      sortedCitations = sortedCitations.sort((a, b) => a.total_count >= b.total_count);      
    }
    if (sortBy === 'totalCountDesc') {      
      sortedCitations = sortedCitations.sort((a, b) => b.total_count >= a.total_count);      
    }
    return sortedCitations.slice()
  }

  const citationAction = (citation, action) => {
    if (action === 'view_citations') {
      console.log(citation)
    }
  }

  const getCsvStatus = (citation) => {
    if (citation?.status === 'void') {
      return ('Void')
    } else if (citation?.payment_on) {
      return ('Paid')
    } else if (citation?.dispute?.status === 'open') {
      return ('Disputed')
    } else if (citation?.status === 'closed') {
      return ('Closed')
    } else {
      return ('Unpaid')
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      {/* First Row of Posts */}
      <Row>
        <Col>
          <Row noGutters className="page-header py-4">
            <PageTitle xs="12" sm="4" title="Violators" className="text-sm-left mb-3" />
            <Col xs="12" sm="8" className='text-right'>
              <Row>
                <Col xs="1"></Col>
                <Col className='text-right pl-0 pr-1'>
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} customInput={<FormInput style={{textAlign: 'right'}} />} maxDate={new Date()} />
                  <div className={'d-none d-xl-block'} style={{position: 'absolute', top: 4, left: 8}}><span style={{fontSize: 11, fontWeight: 'normal'}}>From</span></div>
                </Col>
                <Col className='text-right pl-0 pr-1'>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} customInput={<FormInput style={{textAlign: 'right'}} />} minDate={startDate} />
                  <div className={'d-none d-xl-block'} style={{position: 'absolute', top: 4, left: 8}}><span style={{fontSize: 11, fontWeight: 'normal'}}>To</span></div>
                </Col>
                {props.user.type === 'super' && (
                  <Col className='text-right pl-0 pr-1'>
                    <FormSelect id="feClients" onChange={event => setClient(event.target.value)}>
                      <option value={'all'}>All clients</option>
                      {props.clients?.sort((a, b) => a.name.localeCompare(b.name)).map(client => (
                        <option value={client.id}>{client.name}</option>
                      ))}
                    </FormSelect>
                  </Col>
                )}
                <Col className='text-right pl-0 pr-1'>
                  <FormSelect value={location} id="feLocations" onChange={event => setLocation(event.target.value)}>
                    <option value={'all'}>All locations</option>
                    {props.locations?.sort((a, b) => a.name.localeCompare(b.name)).map(location => (
                      <option value={location.id}>{location.name}</option>
                    ))}
                  </FormSelect>
                </Col>
                <Col className='text-right pl-0 pr-1'>
                  <FormSelect id="feInputState" value={status} onChange={event => setStatus(event.target.value)}>
                    <option value={'all'}>All statuses</option>
                    <option value={'paid'}>Paid</option>
                    <option value={'unpaid'}>Unpaid</option>
                    <option value={'disputed'}>Disputed</option>
                    <option value={'closed'}>Closed</option>
                    <option value={'void'}>Void</option>
                  </FormSelect>
                </Col>
                <DownloadButton
                  filename={"enforceplus_citations"}
                  fileData={generateFileData()}
                  showPdfLoading={endablePdfMode}
                  createPdf={() => generateFilePdf()}
                />
              </Row>
            </Col>
          </Row>
          {props.gettingAllCitations ? (
            <Row>
              <Col className={'text-center'}>
                <ActivityIndicator />
              </Col>
            </Row>
          ) : (
            <>  
              <Card small className="mb-4 mt-4">
                <CardHeader>
                  <Row>
                    <Col xs={8}>&nbsp;</Col>
                    <Col xs={4} className='text-right pl-0'>
                      <FormInput type={'text'} className={'form-control'} placeholder={'Search'} value={search} onChange={event => setSearch(event.target.value)} />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0" id="content-id">
                  <table className="table mb-0">
                    <thead className="bg-none">
                      <tr>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'licenseAsc' ? 'licenseAsc' : 'licenseDesc')} style={{cursor: 'pointer'}}>License plate</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'totalCountAsc' ? 'totalCountAsc' : 'totalCountDesc')} style={{cursor: 'pointer'}}>Count</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div>Total (Sum)</div>
                        </th>
                        {/* <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div>Actions</div>
                        </th>
                        { !endablePdfMode &&
                          <th scope="col" className="border-0">
                          
                          </th>
                        } */}
                      </tr>
                    </thead>
                    <tbody>
                      {props.citations && filterCitations().length > 0 && generateList().map(citation => {
                        return (
                          <>
                            <tr key={citation.id}>
                              <td style={{paddingTop: 24}}>
                                {citation.license_plate} {props.users.find(user => user.id === citation.user)?.name}
                              </td>
                              <td style={{paddingTop: 24}} className={"d-none d-md-table-cell"}>
                                {citation.total_count}
                              </td>                             
                              <td style={{paddingTop: 24}}>
                                ${citation.citations?.reduce((a, b) => a + b.total, 0)}
                              </td>
                              {/* { !endablePdfMode &&
                                <td className={"text-right d-none d-md-table-cell"}>
                                  <FormSelect id="feInputState" onChange={event => {citationAction(citation, event.target.value); event.target.value = ''}}>
                                    <option value={''}>Actions</option>
                                    <option value={'view_citations'}>View citations</option>
                                  </FormSelect>
                                </td>
                              } */}
                            </tr>
                            {/* <tr className="d-md-none d-xs-table-row">
                              <td className="border-top-0"></td>
                              <td colSpan={3} className={"text-right border-top-0"}>
                                <FormSelect id="feInputState" onChange={event => {citationAction(citation, event.target.value); event.target.value = ''}}>
                                  <option value={''}>Actions</option>
                                  <option value={'view_citations'}>View citations</option>
                                </FormSelect>
                              </td>
                            </tr> */}
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Pagination
                data={filterCitations()}
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}      
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Violators;
