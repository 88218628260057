import MainNavbar from './MainNavbar';
import {ActionCreators} from '../../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser} from '../../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, impersonated, impersonatedUser} = getUser(state);  

  return {
    user,
    impersonated,
    impersonatedUser
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainNavbar);
