/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { accessCheck } from "../../utils/tools";

const CallbackLoading = (props) => {
  const params = (new URL(document.location)).searchParams;
  const [state] = useState(params.get("state"));
  const [code] = useState(params.get("code"));
  const [error] = useState(params.get("error"));


  useEffect(() => {
    const tokenSync = async (location) => {
      await props.getSquareToken(location, code);
      await props.getSquareLocations(location)
      window.location.href = '/edit-location/' + state
    }
    if (state && code) {
      const location = props.locations.find(location => location.id === state)
      tokenSync(location)
    }
  }, [state, code])

  useEffect(() => {
    props.getLiveLocations(props.user.id)
    !accessCheck(props.user, 'locations') && (window.location.href = '/')
  }, [])

  return (
    <Container fluid className="main-content-container px-0">
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {!props.removeTitles && (
          <Row noGutters className="page-header py-4">
            <Col xs="12" sm="8">
              <PageTitle sm="12" title="loading" className="text-sm-left" />
            </Col>
          </Row>
        )}
        {/* First Row of Posts */}
        <Row className="justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
          <Col xs="12" className="text-center">
            <div className="mb-4">
              {(state && code) && (
                <div>
                  <p>Connecting with Square and getting locations</p>
                  <div className="mt-3">Please wait...</div>
                </div>
              )}
              {error && (
                <div>
                  <p>Error getting token please try again</p>
                  <Button
                    className="mt-3"
                    theme="outline-secondary"
                    onClick={() => window.location.href = '/'}
                  >
                    Go Home
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )

}

export default CallbackLoading;
