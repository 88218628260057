import firebase from 'firebase';
import emailjs from '@emailjs/browser';
import * as actionConstants from '../actionConstants';
const axios = require('axios');

export const getUser = () => async dispatch => {
  dispatch({ type: actionConstants.LOADING_USER });
  let currentUser = await firebase.auth().currentUser;

  return firebase
    .firestore()
    .collection('users')
    .doc(currentUser.uid)
    .get()
    .then(doc => {
      dispatch({ type: actionConstants.LOADED_USER, payload: {id: doc.id, ...doc.data()}})
    })
    .catch(error => console.log(error))

};

export const clearAddedUser = () => dispatch => {
  dispatch({type: actionConstants.CLEAR_ADDED_USER})
}

export const getUsers = () => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_USERS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collection('users')
    .get()
    .then(querySnapshot => {
      let users = [];
      querySnapshot.forEach(doc => {
        if (doc.data()?.type !== 'tenant') {
          users.push({id: doc.id, ...doc.data()})
        }
      })
      dispatch({ type: actionConstants.LOADED_USERS, payload: users})
    })
    .catch(error => console.log(error))
  } else {
    return firebase
      .firestore()
      .collection('users')
      .where('client', '==', getState().user.user.client)
      .get()
      .then(querySnapshot => {
        let users = [];
        querySnapshot.forEach(doc => {
          if (doc.data()?.type !== 'tenant') {
            users.push({id: doc.id, ...doc.data()})
          }
        })
        dispatch({ type: actionConstants.LOADED_USERS, payload: users})
      })
      .catch(error => console.log(error))
  }
} 

  

export const getLiveUsers = (userId) => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_USERS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collection('users')
    .onSnapshot(querySnapshot => {
      let users = [];
      querySnapshot.forEach(doc => {
        if (doc.data()?.type !== 'tenant') {
          users.push({id: doc.id, ...doc.data()})
        }
      })
      dispatch({ type: actionConstants.LOADED_USERS, payload: users})
    })
  } else {
    return firebase
      .firestore()
      .collection('users')
      .where('client', '==', getState().user.user.client)
      .onSnapshot(querySnapshot => {
        let users = [];
        querySnapshot.forEach(doc => {
          if (doc.data()?.type !== 'tenant') {
            users.push({id: doc.id, ...doc.data()})
          }
        })
        dispatch({ type: actionConstants.LOADED_USERS, payload: users})
      })
  }
};

export const getLiveTenants = (userId) => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_TENANTS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collection('users')
    .onSnapshot(querySnapshot => {
      let tenants = [];
      querySnapshot.forEach(doc => {
        if (doc.data()?.type === 'tenant' && doc.data().active) {
          tenants.push({id: doc.id, ...doc.data()})
        }
      })
      dispatch({ type: actionConstants.LOADED_TENANTS, payload: tenants})
    })
  } else {
    return firebase
      .firestore()
      .collection('users')
      .where('client', '==', getState().user.user.client)
      .onSnapshot(querySnapshot => {
        let tenants = [];
        querySnapshot.forEach(doc => {
          if (doc.data()?.type === 'tenant' && doc.data().active) {
            tenants.push({id: doc.id, ...doc.data()})
          }
        })
        dispatch({ type: actionConstants.LOADED_TENANTS, payload: tenants})
      })
  }
};

export const getTenants = () => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_TENANTS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collection('users')
    .get()
    .then(querySnapshot => {
      let tenants = [];
      querySnapshot.forEach(doc => {
        if (doc.data()?.type === 'tenant' && doc.data().active) {
          tenants.push({id: doc.id, ...doc.data()})
        }
      })
      dispatch({ type: actionConstants.LOADED_TENANTS, payload: tenants})
    })
    .catch(error => console.log(error))
  } else {
    return firebase
      .firestore()
      .collection('users')
      .where('client', '==', getState().user.user.client)
      .get()
      .then(querySnapshot => {
        let tenants = [];
        querySnapshot.forEach(doc => {
          if (doc.data()?.type === 'tenant' && doc.data().active) {
            tenants.push({id: doc.id, ...doc.data()})
          }
        })
        dispatch({ type: actionConstants.LOADED_TENANTS, payload: tenants})
      })
      .catch(error => console.log(error))
  }
} 

export const getLocationTenants = (location) => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_TENANTS });

  return firebase
    .firestore()
    .collection('users')
    .where('location', '==', location.id)
    .where('type', '==', 'tenant')
    .where('active', '==', true)
    .get()
    .then(querySnapshot => {
      let tenants = [];
      querySnapshot.forEach(doc => {
        tenants.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_TENANTS, payload: tenants})
    })
    .catch(error => console.log(error))
} 

/**
 * Add a user
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
 export const addUser = (data, callback) => {
  return async (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_USER});
    let alreadyExists = await firebase
        .firestore()
        .collection('users')
        .where('email', '==', data.email.toLowerCase())
        .get()
        .then(querySnapshot => {
          return querySnapshot.size > 0
        })
    if (alreadyExists) {
      dispatch({type: actionConstants.ADDING_USER_FAILED, payload: 'A user already exists with that email address'});
    }    
    const createUserResponse = await axios.post('https://us-central1-enforceplus-25667.cloudfunctions.net/createUser', {email: data.email.toLowerCase(), password: data.password})
    if (createUserResponse.status === 200) {
      const uid = createUserResponse.data.uid
      const userData = {
        name: data.name,
        email: data.email.toLowerCase(),
        client: data.client,
        type: data.type,
        active: data.active,
      };
      return firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .set(userData)
        .then(doc => {
          dispatch({
            type: actionConstants.ADDED_USER,
            payload: {id: uid, user: getState().user.user.id, ...userData},
          });
          callback && callback({id: uid, user: getState().user.user.id, ...userData})
        });
      } else {
        callback && callback()
      }
  };
};

/**
 * Edit a user
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const editUser = (user, data, callback) => {
  return async (dispatch, getState) => {
    dispatch({type: actionConstants.UPDATING_USER});
    const userData = {
      name: data.name,
      email: data.email,
      client: data.client,
      type: data.type,
      active: data.active,
    };
    if (data.email && user.id === getState().user.user.id) {
      await firebase.auth().currentUser.updateEmail(data.email.toLowerCase())
    }
    return firebase
      .firestore()
      .collection('users')
      .doc(user.id)
      .update(userData)
      .then(doc => {
        dispatch({
          type: actionConstants.UPDATED_USER,
          payload: {id: user.id, user: getState().user.user.id, ...userData},
        });
        callback && callback({id: user.id, user: getState().user.user.id, ...userData})
      });
  };
};

/**
 * Add a tenant
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const addTenant = (data, callback) => {
  return async (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_TENANT});
    let alreadyExists = await firebase
        .firestore()
        .collection('users')
        .where('email', '==', data.email.toLowerCase())
        .get()
        .then(querySnapshot => {
          return querySnapshot.size > 0
        })
    if (alreadyExists) {
      dispatch({type: actionConstants.ADDING_TENANT_FAILED, payload: 'A user already exists with that email address'});
    } 
    const createUserResponse = await axios.post('https://us-central1-enforceplus-25667.cloudfunctions.net/createUser', {email: data.email.toLowerCase(), password: data.password})
    if (createUserResponse.status === 200) {
      const uid = createUserResponse.data.uid
      const userData = {
        name: data.name,
        email: data.email.toLowerCase(),
        client: data.client,
        type: "tenant",
        active: true,
        location: data.location || null,
        zone: data.zone || null,
        vehicle_limit: data.vehicle_limit || null,
        created_at: data.created_at || null,
        created_by: data.created_by || null,
      };
      return firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .set(userData)
        .then(async (doc) => {
          dispatch({
            type: actionConstants.ADDED_TENANT,
            payload: {id: uid, user: getState().user.user.id, ...userData},
          });
          let thisLocation = getState().location?.locations?.find(loc => loc.id === data.location)
          let locationName = thisLocation?.name || ''
          let locationAddress = thisLocation?.address1 + ', ' + thisLocation?.city
          let clientName = getState().client?.clients?.find(cli => cli.id === data.client)?.name
          let emailSend = await emailjs.send("service_cghbh4g","template_ecc93ie", {
            name: data.name,
            to_email: data.email.toLowerCase(),
            location_name: locationName,
            location_address: locationAddress,
            client_name: clientName
          }, 'Bdnrcb9nkMG24q1Uv');
          callback && callback({id: uid, user: getState().user.user.id, ...userData})
        });

      } else {
        callback && callback()
      }
  };
};

/**
 * Edit a tenant
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const editTenant = (tenant, data) => {
  return (dispatch, getState) => {
    const userData = {
      name: data.name,
      location: data.location,
      zone: data.zone,
      vehicle_limit: data.vehicle_limit,
    };
    return firebase
      .firestore()
      .collection('users')
      .doc(tenant.id)
      .update(userData)
      .then(doc => {
        dispatch(getTenants());
      });
  };
};

/**
 * Remove a tenant
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const softDeleteTenant = (tenant) => {
  return async (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('users')
      .doc(tenant.id)
      .update({
        active: false
      })
      .then(doc => {
        dispatch(getTenants());
      });
  };
};


export const impersonate = (userId) => {
  return async (dispatch, getState) => {    
    dispatch({type: actionConstants.IMPERSONATING});    
    const authImpersonate = firebase.functions().httpsCallable('authImpersonate')    
    try {
      const originalUserResult = await authImpersonate({userId: getState().user.user.id});
      dispatch({type: actionConstants.IMPERSONATING_SAVE_TOKEN, payload: originalUserResult.data.token});
      const impersonatedUserResult = await authImpersonate({userId});
      firebase.auth().signInWithCustomToken(impersonatedUserResult.data.token).then(() => {
        dispatch({type: actionConstants.IMPERSONATED, payload: impersonatedUserResult.data.impersonatedUser});
      })
    } catch (error) {
      console.log(error);
      dispatch({type: actionConstants.IMPERSONATE_FAILED});
    }
  };
}

export const stopImpersonate = () => {
  return async (dispatch, getState) => {    
    dispatch({type: actionConstants.IMPERSONATING});
    firebase.auth().signInWithCustomToken(getState().user.impersonatedOriginalToken).then(() => {
      dispatch({type: actionConstants.IMPERSONATE_STOPPED});
    })
  };
}

/**
 * Add a user
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const moveLocations = (data, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionConstants.UPDATING_CLIENT });

    const originalClientRef = firebase.firestore()
      .collection('clients')
      .doc(data.originalClient);

    const newClientRef = firebase.firestore()
      .collection('clients')
      .doc(data.newClient);

    try {
      // Check if locations exist
      const locationsSnapshot = await originalClientRef.collection('locations').get();
      const locationIds = locationsSnapshot.docs.map(doc => doc.id);
      const invalidLocations = data.locations.filter(loc => !locationIds.includes(loc));

      if (invalidLocations.length > 0) {
        dispatch({ type: actionConstants.ADDING_USER_FAILED, payload: 'Locations do not exist on the original client' });
        return;
      }

      // Helper function to move subcollections
      const moveSubcollections = async (subcollections, sourceLocationRef, targetLocationRef) => {
        for (const sub of subcollections) {
          const subcollectionSnapshot = await sourceLocationRef.collection(sub).get();
          for (const doc of subcollectionSnapshot.docs) {
            await targetLocationRef.collection(sub).doc(doc.id).set(doc.data());
          }
        }
      };

      // Subcollections to process
      const subcollections = ['activities', 'tickets', 'violations', 'whitelist'];

      // Move locations and their subcollections
      for (const loc of data.locations) {
        const sourceLocationRef = originalClientRef.collection('locations').doc(loc);
        const targetLocationRef = newClientRef.collection('locations').doc(loc);

        const locationSnapshot = await sourceLocationRef.get();
        if (locationSnapshot.exists) {
          await targetLocationRef.set({...locationSnapshot.data(), client: data.newClient});

          // Move all subcollections
          await moveSubcollections(subcollections, sourceLocationRef, targetLocationRef);

          // Delete the original location
          await sourceLocationRef.delete();
        }
      }

      dispatch({ type: actionConstants.UPDATED_CLIENT });
      callback && callback();
    } catch (error) {
      console.error('Error moving locations:', error);
      dispatch({ type: actionConstants.ADDING_USER_FAILED, payload: 'An error occurred while moving locations' });
    }
  };
};