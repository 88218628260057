// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  user: {},
  users: [],
  lastUser: {},
  loadingUser: false,
  loadedUser: false,
  loadingUsers: false,
  loadedUsers: false,
  updatingUser: false,
  updatedUser: false,
  addingUser: false,
  addingUserFailed: false,
  addedUser: false,
  tenants: [],
  tenant: {},
  loadingTenants: false,
  loadedTenants: false,
  addingTenant: false,
  addedTenant: false,
  addingTenantFailed: false,
  impersonating: false,
  impersonationFailed: false,
  impersonated: false,
  impersonatedUser: {},
  impersonatedOriginalToken: ''
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_USER:
      return {
        ...state, loadingUser: true, loadedUser: false, user: {}
      };
    case actionConstants.LOADED_USER:
      return {
        ...state, loadingUser: false, loadedUser: true, user: action.payload
      };
    case actionConstants.LOADING_USERS:
      return {
        ...state, loadingUsers: true, loadedUsers: false, users: []
      };
    case actionConstants.LOADED_USERS:
      return {
        ...state, loadingUsers: false, loadedUsers: true, users: action.payload
      }; 
    case actionConstants.ADDING_USER:
      return {
        ...state, addingUser: true, addedUser: false, lastUser: {}, addingUserFailed: false,
      };
    case actionConstants.ADDING_USER_FAILED:
      return {
        ...state, addingUser: false, addedUser: false, addingUserFailed: action.payload,
      };  
    case actionConstants.ADDED_USER:
      return {
        ...state, addingUser: false, addedUser: true, lastUser: action.payload
      };
    case actionConstants.UPDATING_USER:
      return {
        ...state, updatingUser: true, updatedUser: false, lastUser: {}
      };
    case actionConstants.UPDATED_USER:
      return {
        ...state, updatingUser: false, updatedUser: true, lastUser: action.payload
      };
    case actionConstants.CLEAR_ADDED_USER:
      return {
        ...state, addingUser: false, addedUser: false, updatingUser: false, updatedUser: false
      };
    case actionConstants.LOADING_TENANTS:
      return {
        ...state, loadingTenants: true, loadedTenants: false, tenants: [], addingTenant: false
      };
    case actionConstants.LOADED_TENANTS:
      return {
        ...state, loadingTenants: false, loadedTenants: true, tenants: action.payload
      };
    case actionConstants.ADDING_TENANT:
      return {
        ...state, addingTenant: true, addedTenant: false, addingTenantFailed: false,
      };
    case actionConstants.ADDING_TENANT_FAILED:
      return {
        ...state, addingTenant: false, addedTenant: false, addingTenantFailed: action.payload,
      };  
    case actionConstants.ADDED_TENANT:
      return {
        ...state, addingTenant: false, addedTenant: true
      };  
    case actionConstants.IMPERSONATING:
      return {
        ...state, impersonating: true, impersonationFailed: false, impersonated: false
      };
    case actionConstants.IMPERSONATING_SAVE_TOKEN:
      return {
        ...state, impersonatedOriginalToken: action.payload
      };
    case actionConstants.IMPERSONATED:
      return {
        ...state, impersonating: false, impersonated: true, impersonatedUser: state.user, user: action.payload
      };
    case actionConstants.IMPERSONATE_FAILED:
      return {
        ...state, impersonating: false, impersonationFailed: true
      };
    case actionConstants.IMPERSONATE_STOPPED:
      return {
        ...state, impersonating: false, impersonated: false, user: state.impersonatedUser, impersonatedUser: {}
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getUser = ({
  user, loadingUser, loadedUser, loadingUsers, loadedUsers, addingUser, addedUser, updatingUser, updatedUser, users, lastUser, loadingTenants, loadedTenants, tenants, addedTenant, addingTenant, addingUserFailed, addingTenantFailed, impersonating, impersonationFailed, impersonated, impersonatedUser
}) => ({
  user,
  loadingUser,
  loadedUser,
  loadingUsers,
  loadedUsers,
  addingUser,
  addedUser,
  updatingUser,
  updatedUser,
  users,
  lastUser,
  loadingTenants,
  loadedTenants,
  tenants,
  addedTenant,
  addingTenant,
  addingUserFailed,
  addingTenantFailed,
  impersonating,
  impersonationFailed,
  impersonated,
  impersonatedUser
});
